const STATE = [
  {
    id: 1,
    name: "北海道",
    en: "Hokkaidô",
  },
  {
    id: 2,
    name: "青森県",
    en: "Aomori",
  },
  {
    id: 3,
    name: "岩手県",
    en: "Iwate",
  },
  {
    id: 4,
    name: "宮城県",
    en: "Miyagi",
  },
  {
    id: 5,
    name: "秋田県",
    en: "Akita",
  },
  {
    id: 6,
    name: "山形県",
    en: "Yamagata",
  },
  {
    id: 7,
    name: "福島県",
    en: "Hukusima",
  },
  {
    id: 8,
    name: "茨城県",
    en: "Ibaraki",
  },
  {
    id: 9,
    name: "栃木県",
    en: "Totigi",
  },
  {
    id: 10,
    name: "群馬県",
    en: "Gunma",
  },
  {
    id: 11,
    name: "埼玉県",
    en: "Saitama",
  },
  {
    id: 12,
    name: "千葉県",
    en: "Tiba",
  },
  {
    id: 13,
    name: "東京都",
    en: "Tôkyô",
  },
  {
    id: 14,
    name: "神奈川県",
    en: "Kanagawa",
  },
  {
    id: 15,
    name: "新潟県",
    en: "Niigata",
  },
  {
    id: 16,
    name: "富山県",
    en: "Toyama",
  },
  {
    id: 17,
    name: "石川県",
    en: "Isikawa",
  },
  {
    id: 18,
    name: "福井県",
    en: "Hukui",
  },
  {
    id: 19,
    name: "山梨県",
    en: "Yamanasi",
  },
  {
    id: 20,
    name: "長野県",
    en: "Nagano",
  },
  {
    id: 21,
    name: "岐阜県",
    en: "Gihu",
  },
  {
    id: 22,
    name: "静岡県",
    en: "Sizuoka",
  },
  {
    id: 23,
    name: "愛知県",
    en: "Aiti",
  },
  {
    id: 24,
    name: "三重県",
    en: "Mie",
  },
  {
    id: 25,
    name: "滋賀県",
    en: "Siga",
  },
  {
    id: 26,
    name: "京都府",
    en: "Kyôto",
  },
  {
    id: 27,
    name: "大阪府",
    en: "Ôsaka",
  },
  {
    id: 28,
    name: "兵庫県",
    en: "Hyôgo",
  },
  {
    id: 29,
    name: "奈良県",
    en: "Nara",
  },
  {
    id: 30,
    name: "和歌山県",
    en: "Wakayama",
  },
  {
    id: 31,
    name: "鳥取県",
    en: "Tottori",
  },
  {
    id: 32,
    name: "島根県",
    en: "Simane",
  },
  {
    id: 33,
    name: "岡山県",
    en: "Okayama",
  },
  {
    id: 34,
    name: "広島県",
    en: "Hirosima",
  },
  {
    id: 35,
    name: "山口県",
    en: "Yamaguti",
  },
  {
    id: 36,
    name: "徳島県",
    en: "Tokusima",
  },
  {
    id: 37,
    name: "香川県",
    en: "Kagawa",
  },
  {
    id: 38,
    name: "愛媛県",
    en: "Ehime",
  },
  {
    id: 39,
    name: "高知県",
    en: "Kôti",
  },
  {
    id: 40,
    name: "福岡県",
    en: "Hukuoka",
  },
  {
    id: 41,
    name: "佐賀県",
    en: "Saga",
  },
  {
    id: 42,
    name: "長崎県",
    en: "Nagasaki",
  },
  {
    id: 43,
    name: "熊本県",
    en: "Kumamoto",
  },
  {
    id: 44,
    name: "大分県",
    en: "Ôita",
  },
  {
    id: 45,
    name: "宮崎県",
    en: "Miyazaki",
  },
  {
    id: 46,
    name: "鹿児島県",
    en: "Kagosima",
  },
  {
    id: 47,
    name: "沖縄県",
    en: "Okinawa",
  },
];

const COLORS = {
  BG_COLOR: "#fafafa",
  PRIMARY_COLOR: "#fff",
  EXPORT_COLOR: "#1b9c4f",
  ACCENT_COLOR: "#32c6b4",
  CALENDAR_COLOR: "#32c6b4",
  WARNING_COLOR: "#ff7b52",
  CANCEL_COLOR: "#f0f0f0",
  BORDER_COLOR: "#d3d3d3",
  ERROR_COLOR: "#ff0000",
};

const DIALOG_TITLE_ADDITIONS = {
  ADD: "＿新規登録",
  EDIT: "＿編集",
  VIEW: "＿閲覧",
};

//１ページあたりのテーブル件数
const TABLES_PER_PAGE = [25, 50, 75, 100];

const TABLE_SORT_ORDERS = [
  { id: "asc", name: "昇順" },
  { id: "dec", name: "降順" },
];

const NO_DATA_MESSAGE = "データがありません。";

const COMPANY_TYPE = {
  OWNER: 0,
  PATNER: 1,
  RENTAL: 2,
  ADMIN: 9,
};

const COMPANY_CONTRACT = {
  PRIME_CONTRACTOR: { id: 0, name: "元請" },
  COMPANY_RENTAL: { id: 1, name: "協力会社" },
  PARTNER_COMPANY: { id: 2, name: "レンタル" },
};

const DIALOG_BUTTON_NAMES = {
  CLOSE: "閉じる",
  CONFIRM: "確認",
  ACCEPT: "確定",
  ADD: "追加",
  UNDO: "取消",
  EXPORT: "出力",
  INVITE: "招待",
  END: "終了",
  CANCEL: "キャンセル",
  DELETE: "削除",
  SAVE: "保存",
  EDIT: "編集",
  RESET: "リセット",
  UNLOCK: "解除",
  APPROVAL_UNLOCK: "承認解除",
  APPROVAL: "承認",
  GUIDANCE: "指導",
  CONTINUE: "続けて登録",
  NO: "いいえ",
  YES: "はい",
  OK: "OK",
};

const COMPORATE_SITE = "https://www.houjin-bangou.nta.go.jp/";

export {
  STATE,
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
  COMPANY_TYPE,
  COMPANY_CONTRACT,
  DIALOG_BUTTON_NAMES,
  COMPORATE_SITE,
  COLORS,
  DIALOG_TITLE_ADDITIONS,
};
