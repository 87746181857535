<template>
  <v-card class="dialog">
    <div class="header">
      <v-card-title>
        <div class="gotoMaster d-flex flex-column">
          <div class="d-flex align-end mb-3">
            <v-btn v-if="gotoMaster" @click="onClickGotoMaster" color="primary"
              >マスタ登録画面へ</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="title">{{ title }}</div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :formValues="formValues"
      :top="120"
      @search="search"
      @formUpdate="formUpdate"
    >
      <v-list two-line>
        <div v-for="item in formValues.items" :key="item.field_id">
          <v-list-item ripple>
            <v-list-item-content @click="onClick(item.field_id)">
              <v-list-item-title class="field_name">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle class="address">{{
                item.prefecture + " " + item.city + " " + item.address
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <div v-if="formValues.items.length == 0" style="text-align: center">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
    };
  },
  components: {
    SearchSelectList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: () => {
        return {
          // 検索ワード
          search: "",
          // 表示するリスト
          items: [],
          // 選択項目id
          selected: null,
        };
      },
    },
    onSearch: Function,
    close: Function,
    gotoMaster: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.$emit("onSearch");
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.$emit("formUpdate", values);
    },

    // idの更新
    onClick(id) {
      const values = { ...this.formValues };
      values.selected = id;
      this.$emit("formUpdate", values);
    },

    onClickGotoMaster() {
      this.$router
        .push(HEADER_MENU_ITEMS_INHOUSE.MACHINES.path)
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.dialog {
  .v-sheet {
    background-color: $background_color !important;
  }
  .header {
    position: sticky;
    top: 0;
    background-color: $background_color;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
.gotoMaster {
  width: 100% !important;
}
.field_name {
  font-size: 18px !important;
  font-weight: bold !important;
}
.address {
  font-size: 12px !important;
}
</style>
