<template>
  <div class="tab-two-content">
    <form>
      <div class="precaution">
        <div class="d-flex justify-space-between list-precautions">
          <div class="item">
            <InputText
              name="precautions_add"
              :editable="true"
              :maxlength="100"
              :values="inputData"
              placeholder="注意事項"
              validation_label="注意事項"
              validation_rules="max:100"
              @onInput="onInput"
            />
          </div>
          <v-btn
            icon
            :disabled="disabledPlus.precaution"
            @click="addDataEmit(typeInput.precaution)"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
        <draggable
          class="scrollbar"
          v-model="listPrecaution"
          @change="$emit('editPrecaution', listPrecaution)"
        >
          <div v-for="(item, key) in listPrecaution" :key="key">
            <div class="item-checkbox">
              <input
                @keyup="changeValueInput($event, 100)"
                @change="changeValue($event, typeCheckBox.precaution)"
                v-bind:value="item.precautions_id"
                type="checkbox"
                class="checkbox-input"
              />
              <input
                v-model="item.precautions"
                class="txt"
                @change="$emit('editPrecaution', listPrecaution)"
              />
            </div>
            <div class="line" v-if="key < listPrecaution.length - 1"></div>
          </div>
        </draggable>
      </div>
      <div class="daily-item">
        <div class="list-daily">
          <div class="d-flex input-item">
            <div class="item w-50 mr-5">
              <InputText
                name="inspection_item_add"
                :editable="true"
                :maxlength="100"
                :values="inputData"
                placeholder="点検事項"
                validation_label="点検事項"
                validation_rules="max:100"
                @onInput="onInput"
              />
            </div>
            <div class="item w-50 ml-5">
              <InputText
                name="main_point_add"
                :editable="true"
                :maxlength="100"
                :values="inputData"
                placeholder="主眼点"
                validation_label="主眼点"
                validation_rules="max:100"
                @onInput="onInput"
              />
            </div>
          </div>
          <v-btn
            icon
            :disabled="disabledPlus.dailyItem"
            @click="addDataEmit(typeInput.dailyItem)"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
        <draggable
          class="scrollbar"
          v-model="listDailyItem"
          @change="$emit('editDailyItem', listDailyItem)"
        >
          <div v-for="(item, key) in listDailyItem" :key="key">
            <div class="d-flex justify-space-between">
              <div class="item-checkbox w-50 mr-5">
                <input
                  @change="changeValue($event, typeCheckBox.dailyItem)"
                  v-bind:value="[item.inspection_item_id, item.main_point_id]"
                  type="checkbox"
                  class="checkbox-input"
                />
                <input
                  v-model="item.inspection_item"
                  class="txt"
                  @change="$emit('editDailyItem', listDailyItem)"
                />
              </div>
              <div class="item-checkbox w-50 ml-5">
                <input
                  v-model="item.main_point"
                  class="txt"
                  @change="$emit('editDailyItem', listDailyItem)"
                />
              </div>
            </div>
            <div class="line" v-if="key < listDailyItem.length - 1"></div>
          </div>
        </draggable>
      </div>
    </form>
  </div>
</template>

<script>
import draggable from "vuedraggable";

// Components
import InputText from "@/components/forms/elements/InputText";

export default {
  name: "TabTwoContent",
  components: { draggable, InputText },
  data() {
    return {
      isDisabled: true,
      checkedPrecautionItem: [],
      checkedDailyItemItem: [],
      typeCheckBox: {
        precaution: "PRECAUTION",
        dailyItem: "DAILY_ITEM",
      },
      typeInput: {
        precaution: "PRECAUTION",
        dailyItem: "DAILY_ITEM",
      },
      disabledPlus: {
        precaution: true,
        dailyItem: true,
      },
      inputData: {
        precautions_add: null,
        inspection_item_add: null,
        main_point_add: null,
      },
    };
  },
  props: {
    listPrecaution: {
      type: Array,
      default: [],
    },
    listDailyItem: {
      type: Array,
      default: [],
    },
  },
  methods: {
    onInput({ name, value }) {
      const inputData = { ...this.inputData };
      inputData[name] = value;
      this.inputData = inputData;
      if (name == "precautions_add") {
        if (value.length > 0) {
          this.disabledPlus.precaution = false;
        } else {
          this.disabledPlus.precaution = true;
        }
      } else if (name == "inspection_item_add") {
        if (value.length > 0 && this.inputData.main_point_add?.length > 0) {
          this.disabledPlus.dailyItem = false;
        } else {
          this.disabledPlus.dailyItem = true;
        }
      } else if (name == "main_point_add") {
        if (
          value.length > 0 &&
          this.inputData.inspection_item_add?.length > 0
        ) {
          this.disabledPlus.dailyItem = false;
        } else {
          this.disabledPlus.dailyItem = true;
        }
      }
    },
    enableInput() {
      this.isDisabled = false;
    },
    changeValue(event, type) {
      if (type === this.typeCheckBox.precaution) {
        if (event.target.checked) {
          this.checkedPrecautionItem.push(event.target.value);
        } else {
          this.checkedPrecautionItem = this.checkedPrecautionItem.filter(
            (x) => x !== event.target.value
          );
        }
      }
      if (type === this.typeCheckBox.dailyItem) {
        let value = event.target.value.split(",");
        if (event.target.checked) {
          this.checkedDailyItemItem.push({
            inspection_item_id: value[0],
            main_point_id: value[1],
          });
        } else {
          this.checkedDailyItemItem = this.checkedDailyItemItem.filter((x) => {
            return (
              x.inspection_item_id !== value[0] && x.main_point_id !== value[1]
            );
          });
        }
      }
      if (this.checkedDailyItemItem.length === 0) {
        this.$emit("changeValuePrecaution", this.checkedPrecautionItem);
      }
      if (this.checkedPrecautionItem.length === 0) {
        this.$emit("changeValueDailyItemItem", this.checkedDailyItemItem);
      }
      if (
        this.checkedDailyItemItem.length !== 0 &&
        this.checkedPrecautionItem.length !== 0
      ) {
        this.$emit("changeAllValue", {
          checked_precaution_item: this.checkedPrecautionItem,
          checked_daily_item_item: this.checkedDailyItemItem,
        });
      }
    },
    resetCheckBox() {
      this.checkedPrecautionItem = [];
      this.checkedDailyItemItem = [];
      document.querySelector(`input`).unchecked;
    },
    resetDisabledPlus() {
      this.disabledPlus.precaution = true;
      this.disabledPlus.dailyItem = true;
    },
    addDataEmit(type) {
      if (type === this.typeInput.precaution) {
        this.$emit("addPrecaution", this.inputData);
      }
      if (type === this.typeInput.dailyItem) {
        this.$emit("addDailyItem", this.inputData);
      }
      this.resetDisabledPlus();
    },
    changeValueInput(event, max = 255) {
      if (event.target.value !== "") {
        let element = event.target;
        if (element.value.length > max) {
          element.value = element.value.slice(0, max);
        }
      }
      this.lastValue = event.target.value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-text-field__details {
  display: none;
}
.tab-two-content {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.daily-item .scrollbar,
.precaution .scrollbar {
  overflow-y: scroll;
  height: 230px;
}
.tab-two-content .item {
  width: 100%;
}
.tab-two-content .item-checkbox {
  display: flex;
  margin: 10px 0;
}
.tab-two-content .item-checkbox .checkbox-input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.line {
  border-bottom: solid 1px $border_color;
}
.w-50 {
  width: 50%;
  box-sizing: border-box;
}
.list-daily {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 15px 0;
}
.list-daily .input-item {
  width: calc(100% - 40px) !important;
}
.list-precautions {
  margin: 15px 0;
  align-items: center !important;
}
.precautions-text {
  width: calc(100% - 40px) !important;
}
.txt {
  width: calc(100% - 40px) !important;
  cursor: pointer;
  font-size: 14px;
}
</style>
