<template>
  <!--  
      (共通) ソート 
      Vuetifyのコンポーネントを別のコンポーネントで抽象化しないで直接実装しています
    -->
  <div class="tableSortWrapper">
    <div class="sortLabel">並び順:</div>
    <div class="sortElement">
      <v-select
        v-model="sort"
        dense
        :items="sort_item"
        :item-text="sort_item_text"
        :item-value="sort_item_value"
        @change="$emit('onInput', { name: 'sort', value: sort })"
      ></v-select>
    </div>

    <div class="sortElement">
      <v-select
        name="sort_order"
        v-model="asc_val"
        dense
        :items="sort_order_options"
        item-text="name"
        item-value="id"
        @change="$emit('onInput', { name: 'asc', value: asc_val === 'asc' })"
      ></v-select>
    </div>

    <v-spacer></v-spacer>
    <div class="sortLabel">表示件数:</div>
    <div class="sortElement">
      <v-select
        v-model="pageCount"
        dense
        :items="page_counts_options"
        @change="$emit('onInput', { name: 'pageCount', value: pageCount })"
      ></v-select>
    </div>

    <div class="sortLabel">総件数:</div>
    <div class="sortElement total_item">{{ total_item }}件</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort: null,
      asc_val: this.sort_order_options[0], // 'asc'
      pageCount: 25,
      sort_item: [],
    };
  },
  mounted() {
    this.$watch(
      () => this.sort_items,
      (newValue) => {
        this.sort_item = [...newValue];
        this.sort_item.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  props: {
    sort_items: {
      type: Array,
    },
    sort_item_text: {
      type: String,
    },
    sort_item_value: {
      type: String,
    },
    page_counts_options: {
      type: Array,
    },
    sort_order_options: {
      type: Array,
    },
    total_item: {
      type: Number,
    },
    values: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
  }
  .sortElement {
    max-width: 128px;
    margin-right: 24px;
  }
  .total_item {
    padding-bottom: 15px;
  }
}
</style>
