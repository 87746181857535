<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-5"
          color="warning"
          @click="rejectConfirm"
          :disabled="!isShowAction"
        >
          {{ DIALOG_BUTTON_NAMES.APPROVAL_UNLOCK }}
        </v-btn>
        <v-btn
          class="mx-5"
          color="primary"
          @click="approveConfirm"
          :disabled="!isShowAction"
        >
          {{ DIALOG_BUTTON_NAMES.APPROVAL }}
        </v-btn>
        <v-btn icon @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <InputDateMonthRangePicker
            name="inspection_month_picker"
            :values="searchParams"
            @onInput="onChangeSearchParams"
          />
          <v-spacer></v-spacer>
          <PageManager
            class="ml-5"
            :values="searchParams"
            :page_counts_options="TABLES_PER_PAGE"
            :total_item="searchParams.totalItems"
            :pageCount="searchParams.pageCount"
            @onInput="onChangeSearchParams"
          />
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <v-container>
        <div class="note">{{ CHECK_STATUS_MESSAGE.INSPECTION1 }}</div>
        <v-data-table
          v-model="selectedItems"
          :headers="LOG_TABLE_LABELS"
          :items="logList"
          hide-default-footer
          disable-sort
          fixed-header
          :height="params.mainHeight - 50"
          :items-per-page="searchParams.pageCount"
          show-select
          noDataText="データがありません。"
          outlined
        >
          <template v-slot:[`item.approved_status`]="{ item }">
            <div>
              <v-chip :color="item.approval_status == 0 ? 'orange' : 'success'"
                >{{ item.approval_status == 0 ? "未承認" : "承認済" }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.result`]="{ item }">
            <div style="font-size: 25px">
              {{ handleResult(item.result) }}
            </div>
          </template>
          <template v-slot:[`item.inspection_at`]="{ item }">
            <div>
              {{ convertFormatDate(item) }}
            </div>
          </template>
        </v-data-table>

        <Popup width="500px" :dialog="popups.isShowDialog">
          <ConfirmDialog
            @close="closeDialog"
            @yes="actionDialog"
            :isUnlock="true"
            :title="popups.titleDialog"
            :text="popups.messageDialog"
          />
        </Popup>

        <Popup width="500px" :dialog="popups.isShowApprovalDialog">
          <ConfirmDialog
            @close="closeDialog"
            @yes="actionDialog"
            :isApproval="true"
            :title="popups.titleDialog"
            :text="popups.messageDialog"
          />
        </Popup>

        <Popup width="500px" :dialog="popups.isShowConfirmDialog">
          <ConfirmDialog
            @close="popups.isShowConfirmDialog = false"
            @yes="closeForm"
            :isClose="true"
            :isCenter="false"
            title="フォームを閉じる確認"
            text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
          />
        </Popup>
        <Popup width="500px" :dialog="popups.isShowUpdateErrorDialog">
          <ConfirmDialog
            @close="popups.isShowUpdateErrorDialog = false"
            :hasDetails="false"
            :isTwoButton="false"
            :isCenter="false"
            title="承認不可"
            text="自社が点検会社に設定されていない、点検実績も含まれています。"
          />
        </Popup>
      </v-container>
    </template>
    <template #footer>
      <Pagination
        v-if="searchParams.pageCount <= searchParams.totalItems"
        :current="searchParams.currentPage"
        :total="searchParams.totalPage"
        @pageUpdate="pageUpdate"
      />
    </template>
  </FormDialogLayout>
</template>
<script>
import { Store } from "@/store/Store.js";
import { format } from "date-fns";
import {
  ATTRIBUTE_DIALOG_ARRRAY,
  INSPECTION_RESULT_CATEGORY,
  RESULT_STATUS,
  CHECK_STATUS_MESSAGE,
} from "@/constants/INSPECTION";
import { TABLES_PER_PAGE, DIALOG_BUTTON_NAMES } from "@/constants/COMMON";
import { LOG_TABLE_LABELS } from "@/constants/MACHINE_SITES";

// Components
import Popup from "@/components/common/Popup.vue";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import InputDateMonthRangePicker from "@/components/forms/elements/InputDateMonthRangePicker";
import PageManager from "@/components/forms/elements/PageManager";
import Pagination from "@/components/forms/elements/Pagination";

const STORE = "Inspections";
export default {
  components: {
    Popup,
    ConfirmDialog,
    FormDialogLayout,
    InputDateMonthRangePicker,
    PageManager,
    Pagination,
  },
  data() {
    return {
      LOG_TABLE_LABELS,
      DIALOG_BUTTON_NAMES,
      TABLES_PER_PAGE,
      CHECK_STATUS_MESSAGE,
      INSPECTION_RESULT_CATEGORY,
      dataTable: [],
      selectedItems: [],
      popups: {
        titleDialog: "",
        messageDialog: "",
        nameAction: "",
        isShowDialog: false,
        isShowApprovalDialog: false,
        isShowUpdateErrorDialog: false,
        isShowConfirmDialog: false,
      },
      searchParams: {
        id: null,
        inspection_date_from: format(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          "yyyy/MM/dd"
        ),
        inspection_date_to: format(new Date(), "yyyy/MM/dd"),
        pageCount: 25,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
    };
  },
  props: {
    item: Object,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        let searchParams = { ...this.searchParams };
        searchParams.id = data.id;
        this.searchParams = searchParams;
        this.getItems();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    apiParams() {
      return {
        ids: [this.searchParams.id],
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
        page_size: this.searchParams.pageCount,
        page_number: this.searchParams.currentPage,
      };
    },
    title() {
      const textCenter = "　/　";
      return `${
        this.item.middle_classification ? this.item.middle_classification : ""
      }
      ${this.item.machine_name ? `${textCenter} ${this.item.machine_name}` : ""}
      ${
        this.item.machine_field_name
          ? `${textCenter} ${this.item.machine_field_name}`
          : ""
      }`;
    },
    isShowAction() {
      return this.selectedItems.length > 0;
    },
    loginUser() {
      return Store.state.Login.user;
    },
    logList() {
      return this.dataTable.map((x) => ({
        ...x,
        isSelectable: x?.company_id === this.loginUser.company_id,
      }));
    },
  },
  methods: {
    async getItems() {
      const response = await Store.dispatch(
        `${STORE}/getByMachineId`,
        this.apiParams
      );
      if (response.hasError) {
        return;
      }
      const { entries, pagination } = response.data.contents;
      let searchParams = { ...this.searchParams };
      searchParams.totalPage = pagination.total;
      searchParams.currentPage = pagination.current;
      searchParams.totalItems = pagination.total_item;
      this.searchParams = searchParams;
      this.dataTable = entries;
    },
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name == "customMonth") {
        searchParams.inspection_date_from = format(
          new Date(value.fromVal),
          "yyyy/MM/dd"
        );
        searchParams.inspection_date_to = format(
          new Date(value.toVal),
          "yyyy/MM/dd"
        );
      } else {
        searchParams[name] = value;
      }
      this.searchParams = searchParams;
      this.getItems();
    },
    closeForm() {
      this.$emit("close");
    },
    closeDialog() {
      this.popups.isShowDialog = false;
      this.popups.isShowApprovalDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    actionDialog() {
      let action = this.popups.nameAction;
      if (action == INSPECTION_RESULT_CATEGORY.REJECT_APPROVE) {
        this.changeApproveItems(0);
      } else {
        this.changeApproveItems(1);
      }
    },
    async changeApproveItems(approved_status) {
      const ids = this.selectedItems.map((items) => items.id);
      if (ids == null || ids.length == 0) return;
      const result = await Store.dispatch(`${STORE}/updateStatusByGroup`, {
        ids,
        approved_status,
        inspection_frequency: 1,
      });
      if (result.hasError) {
        this.closeDialog();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
        return;
      }
      if (!result.data.contents.entries.update_status) {
        this.closeDialog();
        this.popups.isShowUpdateErrorDialog = true;
        return;
      }
      this.dataTable.forEach((e) => {
        if (ids.includes(e.id)) {
          e.approval_status = approved_status;
        }
      });
      this.closeDialog();
      Store.dispatch("Toast/show", {
        status: 200,
        message: approved_status == 0 ? "承認を解除しました" : "承認しました",
      });
    },
    rejectConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog =
        ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.REJECT_APPROVE;
    },
    approveConfirm() {
      this.popups.isShowApprovalDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS;
    },
    convertFormatDate(item) {
      return format(new Date(item.inspection_at), "yyyy/MM/dd HH:mm:ss");
    },
    handleResult(result) {
      let rs = "×";
      if (result == RESULT_STATUS.USE_AFTER_REPAIR) {
        rs = "●";
      } else if (result == RESULT_STATUS.GOOD) {
        rs = "レ";
      }
      return rs;
    },
  },
};
</script>
