<template>
  <v-card class="mx-auto my-12 mt-0 mb-0 mb-0 pb-8">
    <div class="dialog-header">
      <v-row>
        <v-col cols="10">
          <div class="dialog-title-big">合言葉の設定をして下さい。</div>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click="$emit('close')">
            <v-icon class="icon-close">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <div class="dialog-title-small">
          合言葉 ※パスワード再設定時に使用します
        </div>
      </v-row>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input"
              name="question"
              :values="formValue"
              :editable="true"
              validation_label="質問"
              validation_rules="required|max:255"
              @onInput="onInput"
              placeholder="質問*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <InputText
              :isForResetEmail="true"
              class="custom-input"
              name="answer"
              :values="formValue"
              :editable="true"
              validation_label="回答"
              validation_rules="required|max:255"
              @onInput="onInput"
              placeholder="回答*"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              height="48px"
              color="primary"
              :disabled="invalid"
              @click="LoginWithFilled"
              >設定</v-btn
            >
          </v-list-item-content>
        </v-list-item>
        <div class="links pt-0">
          <v-btn style="color: #083874" text @click="LoginWithoutFilled">
            今はしない</v-btn
          >
        </div>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import InputText from "@/components/forms/elements/InputText.vue";
export default {
  components: {
    InputText,
    ValidationObserver,
  },
  data() {
    return {
      formValue: {
        question: "",
        answer: "",
      },
    };
  },
  methods: {
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },
    LoginWithFilled() {
      const val = this.formValue;
      this.$emit("login-with-fillQA", { val });
    },
    LoginWithoutFilled() {
      this.$emit("login-without-fillQA");
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-header {
  margin-top: 10px;
  padding-left: 8px;
  width: 338px;
  font: 500 normal 20px/24px "Roboto";
  letter-spacing: 0.15px;
  color: #00b59a;
  .dialog-title-big {
    width: inherit;
    margin: 8px 0 0 12px;
  }
  .dialog-title-small {
    margin: -10px 0 27px 26px;
    font-size: 14px;
  }
}
.v-form {
  padding: 0 8px 0 8px;
}
.custom-input {
  ::v-deep .v-input__control {
    margin-bottom: -18px;
  }
  ::v-deep .v-input__slot {
    border: 0.5px double #e0e0e0;
    border-radius: 3.5px;
    padding: 0 26px;
    height: 54px;
    ::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.links {
  padding: 8px;
  text-align: center;
  margin-bottom: -22px;
  ::v-deep .v-btn__content {
    color: #00a688;
  }
}
</style>
