<template>
  <FormDialogLayout
    :right="30"
    :width="40"
    className="user-form"
    :editable="isNewItem"
  >
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          class="mr-5"
          color="primary"
          @click="onEditable"
        >
          {{ DIALOG_BUTTON_NAMES.EDIT }}
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <UserFormPage
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="500px" :dialog="isShowConfirmDialog">
        <ConfirmDialog
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          v-if="editable && isNewItem"
          class="mx-5"
          :disabled="!valid"
          color="primary"
          @click.once="onSubmit(true)"
        >
          {{ DIALOG_BUTTON_NAMES.CONTINUE }}
        </v-btn>
        <v-btn
          v-if="editable"
          class="ml-5"
          :disabled="!valid"
          color="primary"
          :key="submitKey"
          @click.once="onSubmit(false)"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn>
      </v-toolbar>
    </template>
  </FormDialogLayout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import UserFormPage from "@/components/forms/users/UserFormPage.vue";
import { Store } from "@/store/Store.js";
import { TITLE, FORMS, USER_INITAL_ITEM, USER_ROLE } from "@/constants/USER";
import { COMPANY_TYPE, DIALOG_BUTTON_NAMES } from "@/constants/COMMON";

const STORE = "Users";

export default {
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      TITLE,
      FORMS,
      formValues: { ...USER_INITAL_ITEM },
      beforeUpdateItem: {},
      submitKey: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialogLayout,
    ConfirmDialog,
    UserFormPage,
  },
  props: {
    isNewItem: Boolean,
  },
  mounted() {
    /**
     * (Common)
     * Init Screen
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`${STORE}/getUserDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.formValues = { ...data[0] };
          this.beforeUpdateItem = { ...data[0] };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    USER_LOGIN() {
      return Store.state.Login.user;
    },
    isAdmin() {
      if (
        (this.USER_LOGIN.company_type == COMPANY_TYPE.OWNER ||
          this.USER_LOGIN.company_type == COMPANY_TYPE.ADMIN) &&
        this.USER_LOGIN.role == USER_ROLE[0].id
      ) {
        return true;
      }
      return false;
    },
    formtitle() {
      return this.isNewItem ? this.TITLE.NEW : this.TITLE.EDIT;
    },
  },
  methods: {
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    onEditable() {
      this.editable = true;
    },

    onDetail() {
      this.editable = false;
    },

    /**
     * onSubmit
     */
    async onSubmit(flag) {
      const apiParams = { ...this.formValues };
      const hasId = "id" in this.formValues;
      if (!hasId && this.isAdmin && apiParams.role == 0)
        apiParams.invite_flg = 1;
      if (this.isAdmin) apiParams.admin_user_flg = 1;
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/post`,
        apiParams
      );
      if (result.hasError) {
        this.submitKey = !this.submitKey;
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      } else {
        if (this.isNewItem) {
          if (flag) {
            this.$emit("reOpen");
          } else {
            this.$emit("cancel");
          }
        } else {
          this.getItems(apiParams.id);
          this.onDetail();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録・招待しました",
        });
      }
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * handle close popup confirm
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = { ...this.beforeUpdateItem };
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    /**
     * get data user detail
     */
    async getItems(id) {
      if (!this.isNewItem) {
        Store.dispatch(`${STORE}/getUserDetail`, id);
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>
