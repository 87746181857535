<template>
  <v-card class="dialog">
    <v-card-title>
      {{ titleHeader }}
    </v-card-title>
    <v-card-text class="body">
      <slot name="form-data" class="form-data"></slot>
      <div class="action">
        <div>
          <v-btn
            v-if="isDelete"
            icon
            :disabled="disableDelete"
            @click="$emit('delete')"
          >
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>
        </div>
        <div class="button-group mr-5">
          <v-btn class="btn-cancel" @click="$emit('close')">
            {{ DIALOG_BUTTON_NAMES.CANCEL }}
          </v-btn>
          <v-btn
            class="btn-submit mx-5"
            type="submit"
            :disabled="!valid"
            @click="$emit('submit')"
          >
            {{ textBtn.btnSubmit }}
          </v-btn>
          <v-btn
            v-if="isBtnContinue"
            class="btn-submit ml-5"
            :disabled="!valid"
            @click="$emit('continue')"
          >
            {{ DIALOG_BUTTON_NAMES.CONTINUE }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";
export default {
  name: "AddDataSettingMachine",
  components: {},
  data: () => ({ DIALOG_BUTTON_NAMES }),
  props: {
    titleHeader: {
      type: String,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isBtnContinue: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    textBtn: {
      btnSubmit: "保存",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.dialog {
  height: auto !important;
}
.action {
  display: flex;
  justify-content: space-between;
  .button-group {
    display: flex;
  }
}
.btn-submit {
  color: $primary_color !important;
  background: $accent_color !important;
}
.btn-cancel {
  margin-right: 20px;
  background: $primary_color !important;
  color: $accent_color;
  border: solid 1px $accent_color;
}
</style>
