import Vue from "vue";
import Vuex from "vuex";
import { fieldUser } from "../../../api/modules/fieldUser";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = fieldUser;

export const FieldUser = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    fieldUserDetail: {},
    dataLeadApproved: [],
    paginationLeadApproved: {},
    is_user_field: false,
    dataSort: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FIELD_USER_DETAIL(state, payload) {
      state.fieldUserDetail = payload;
    },
    SET_DATA_LEAD_APPROVED(state, payload) {
      state.dataLeadApproved = payload;
    },
    SET_PAGINATION_LEAD_APPROVED(state, payload) {
      state.paginationLeadApproved = payload;
    },
    SET_IS_USER_FIELD(state, payload) {
      state.is_user_field = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      let userNameFilter = payload.user_name;
      if (
        userNameFilter &&
        userNameFilter != "" &&
        !userNameFilter.match(/(?<=\S)\s(?=\S)/g)
      ) {
        userNameFilter = userNameFilter.trim();
        payload.user_name = null;
        payload.page_size = null;
        const responseCustom = await ENTITY.get(payload);
        if (!responseCustom.hasError) {
          let { entries, pagination } = responseCustom.data.contents;
          entries = entries.filter(
            (e) => e.full_name.replace(/ +/g, "").indexOf(userNameFilter) >= 0
          );
          if (payload.page_size) {
            if (
              (payload.page_size == 25 && entries.length >= 25) ||
              (payload.page_size == 50 && entries.length >= 50) ||
              (payload.page_size == 75 && entries.length >= 75) ||
              (payload.page_size == 75 && entries.length >= 100)
            ) {
              pagination.total = Math.floor(entries.length / payload.page_size);
            }
          }
          pagination.total_item = entries.length;
          commit("SET_DATA", entries);
          commit("SET_PAGINATION", pagination);
          responseCustom.data.contents.entries = entries;
          return responseCustom;
        } else {
          return responseCustom;
        }
      } else {
        const response = await ENTITY.get(payload);
        const { entries, pagination } = response.data.contents;
        commit("SET_DATA", entries);
        commit("SET_PAGINATION", pagination);
        return response;
      }
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async representative(_context, payload) {
      return await ENTITY.representative(payload);
    },
    async checkRepresentative({ commit }, payload) {
      const response = await ENTITY.checkRepresentative(payload);
      if (response.hasError) {
        return response;
      }
      const { is_user_field } = response.data.contents.entries;
      commit("SET_IS_USER_FIELD", is_user_field);
    },
    async getListLeadApproved({ commit }, payload) {
      const response = await ENTITY.getListLeadApproved(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA_LEAD_APPROVED", entries);
      commit("SET_PAGINATION_LEAD_APPROVED", pagination);
      return response;
    },
    async updateCheckPast(_context, payload) {
      return await ENTITY.updateCheckPast(payload);
    },
    async getSort(_context, payload) {
      return await ENTITY.get(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getDataLeadApproved: (state) => {
      return state.dataLeadApproved;
    },
    getPaginationLeadApproved: (state) => {
      return state.paginationLeadApproved;
    },
    getIsUserField: (state) => {
      return state.is_user_field;
    },
  },
};
