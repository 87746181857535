<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title> 点検表情報 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div class="d-flex" style="width: 50%">
              <InputText
                class="search width-large"
                name="middle_classification"
                :editable="true"
                placeholder="機械名"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <v-btn
                class="search"
                color="primary"
                :disabled="!actions.isEditting"
                @click="onSearch"
              >
                検索
              </v-btn>
            </div>
          </div>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div class="d-flex" :style="{ height: params.mainHeight + 'px' }">
        <div class="wrap-content border-right" style="flex: 1">
          <div class="d-flex justify-space-between main-header border-bottom">
            <h1>大分類</h1>
            <v-btn icon @click="openDialogAddData()">
              <v-icon class="plus-icon"> mdi-plus </v-icon>
            </v-btn>
          </div>
          <div
            ref="scrollMajor"
            class="scrollbar"
            :style="{ height: params.mainHeight - 50 + 'px' }"
          >
            <div
              v-for="(item, key) in listMajor"
              :key="key"
              @click="
                activeItem(key, levelTab.One, item.major_classification_id)
              "
            >
              <div
                class="d-flex justify-space-between list-item border-bottom"
                :class="{ 'active-item': key == itemActive.tabOne }"
              >
                <div>{{ relateText(item.major_classification) }}</div>
                <v-btn
                  icon
                  @click="
                    openDialogEditMachine(1, item.major_classification_id, key)
                  "
                >
                  <v-icon> mdi-pencil-outline </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-content border-right" style="flex: 1">
          <div class="d-flex justify-space-between main-header border-bottom">
            <h1>機械名</h1>
            <v-btn icon @click="openFormDialogTabTwo()">
              <v-icon class="plus-icon"> mdi-plus </v-icon>
            </v-btn>
          </div>
          <div
            ref="scrollMiddle"
            class="scrollbar"
            :style="{ height: params.mainHeight - 50 + 'px' }"
          >
            <div
              v-for="(item, key) in listMiddle"
              :key="key"
              @click="
                activeItem(key, levelTab.Two, item.middle_classification_id)
              "
            >
              <div
                class="d-flex justify-space-between list-item border-bottom"
                :class="{ 'active-item': key == itemActive.tabTwo }"
              >
                <div>{{ relateText(item.middle_classification) }}</div>
                <div class="d-flex action-icon">
                  <v-btn
                    icon
                    @click="
                      openDialogEditMachine(
                        2,
                        item.middle_classification_id,
                        key
                      )
                    "
                  >
                    <v-icon> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="copyMiddle(item.middle_classification_id)"
                  >
                    <v-icon> mdi-content-copy </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-content border-right" style="flex: 3">
          <div class="border-bottom">
            <div
              class="d-flex justify-space-between tab-header"
              style="width: 100%"
            >
              <div class="flex-column">
                <span>
                  {{ relateText(objectMapping.majorTitle) }}
                </span>
                <br />
                <span>
                  {{ relateText(objectMapping.middleTitle) }}
                </span>
              </div>
              <div>
                <v-tabs v-model="tab" align-with-title>
                  <v-tab v-for="(form, key) in FORMS" :key="key">
                    {{ form.title }}
                  </v-tab>
                </v-tabs>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-space-between"
            style="height: 60px; align-items: center"
          >
            <div>
              <v-btn
                v-if="actions.isShowDelete"
                icon
                :disabled="disableActions.disableDelete"
                @click="onDeleteTab"
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
              <v-btn
                v-if="actions.isShowAddNew"
                color="primary"
                :disabled="disableActions.disableAddNew"
                @click="openDialogAddNew"
              >
                項目追加
              </v-btn>
            </div>
            <div>
              <v-btn
                v-if="actions.isShowTemplateOne"
                color="primary"
                :disabled="disableActions.disableTemplateOne"
                @click="openDialogTemplate(1)"
              >
                プレビュー
              </v-btn>
              <v-btn
                v-if="actions.isShowTemplateTwo"
                color="primary"
                :disabled="disableActions.disableTemplateTwo"
                @click="openDialogTemplate(2)"
              >
                プレビュー
              </v-btn>
            </div>
          </div>
          <v-tabs-items
            v-model="tab"
            :style="{ 'max-height': params.mainHeight - 110 + 'px' }"
          >
            <v-tab-item>
              <TabOneContent
                ref="tabOneContent"
                :filePreview="imageUrl"
                :isTemp="isTemp"
                :key="reloadCount"
                @changeFile="changeFile"
              />
            </v-tab-item>
            <v-tab-item>
              <TabTwoContent
                ref="tabTwoContent"
                :listPrecaution="listPrecaution"
                :listDailyItem="listDailyItem"
                @changeAllValue="changeAllValueCheckBox"
                @changeValuePrecaution="changeValuePrecautionCheckBox"
                @changeValueDailyItemItem="changeValueDailyItemItemCheckBox"
                @addPrecaution="addPrecaution"
                @addDailyItem="addDailyItem"
                @editPrecaution="editPrecaution"
                @editDailyItem="editDailyItem"
              />
            </v-tab-item>
            <v-tab-item>
              <TabThreeContent
                :listMonthlyItem="listMonthlyItem"
                @deleteTestItem="deleteTestItemDetail"
                @plusData="openMonthlyItemDialog"
                @deleteInspectionDetails="deleteInspectionDetails"
                @editMonthlyItem="editMonthlyItem"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
      <ValidationObserver ref="observer" v-slot="observer">
        <Popup width="500px" :dialog="popups.isShowDialogAddNew">
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            :isBtnContinue="false"
            :isDelete="false"
            :disableDelete="true"
            :textBtn="{
              btnSubmit: '保存',
            }"
            :valid="valid"
            @submit="addTestItem"
            @close="closeFormDialog()"
          >
            <template #form-data>
              <Label label="項目" required>
                <InputText
                  name="test_item_add"
                  :editable="true"
                  :maxlength="30"
                  :values="inputData"
                  placeholder="項目"
                  validation_label="項目"
                  validation_rules="required|max:30"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <Popup
          width="500px"
          :dialog="popups.isShowFormDialog"
          :isShowFormEdit="true"
        >
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            :isDelete="true"
            :disableDelete="true"
            :textBtn="{
              btnSubmit: '保存',
            }"
            :valid="valid"
            @submit="addMajor"
            @close="closeFormDialog()"
          >
            <template #form-data>
              <Label label="大分類" required>
                <InputText
                  name="major_classification_add"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="大分類"
                  validation_label="大分類"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <Popup width="500px" :dialog="popups.isShowFormDialogTabTwo">
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            :isDelete="true"
            :disableDelete="true"
            :textBtn="{
              btnSubmit: '保存',
            }"
            :valid="valid"
            @submit="addMiddle"
            @close="closeFormDialog()"
          >
            <template #form-data>
              <Label label="機械名" required>
                <InputText
                  name="middle_classification_add"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="機械名"
                  validation_label="機械名"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
              <Label label="ふりがな" required>
                <InputText
                  name="middle_classification_furigana_add"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="ふりがな"
                  validation_label="ふりがな"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <Popup width="500px" :dialog="popups.isShowDialogEditMachineLevelOne">
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            :textBtn="{
              btnSubmit: '保存',
            }"
            :valid="valid"
            @close="closeFormDialog()"
            @delete="openDialogConfirm(confirmAction.deleteMajor)"
            @submit="editMajor()"
          >
            <template #form-data>
              <Label label="大分類" required>
                <InputText
                  name="major_classification_edit"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="大分類"
                  validation_label="大分類"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <Popup width="500px" :dialog="popups.isShowDialogEditMachineLevelTwo">
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            @submit="editMiddle"
            :textBtn="{
              btnSubmit: '保存',
            }"
            :valid="valid"
            @delete="openDialogConfirm(confirmAction.middle)"
            @close="closeFormDialog()"
          >
            <template #form-data>
              <Label label="機械名" required>
                <InputText
                  name="middle_classification_edit"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="機械名"
                  validation_label="機械名"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
              <Label label="ふりがな" required>
                <InputText
                  name="middle_classification_furigana_edit"
                  :editable="true"
                  :maxlength="255"
                  :values="inputData"
                  placeholder="ふりがな"
                  validation_label="ふりがな"
                  validation_rules="required|max:255"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <Popup width="500px" :dialog="popups.isShowDialogAddMonthlyItem">
          <AddDataSettingMachine
            :titleHeader="titleHeader"
            :isDelete="false"
            :isBtnContinue="true"
            :disableDelete="true"
            :textBtn="{
              btnSubmit: '追加',
            }"
            :valid="valid"
            @continue="addMonthlyItem(true)"
            @submit="addMonthlyItem"
            @close="closeFormDialog()"
          >
            <template #form-data>
              <h2 style="margin-bottom: 20px">
                機械特有の点検項目を追加します。
              </h2>
              <Label label="項目" required>
                <SelectWithFilter
                  name="test_item_id"
                  :items="getSelectListMonthlyItem"
                  :values="inputData"
                  :editable="true"
                  placeholder="項目"
                  validation_label="項目"
                  validation_rules="required"
                  @onInput="onInput"
              /></Label>
              <Label label="点検事項" required>
                <InputText
                  name="inspection_item_add"
                  :editable="true"
                  :maxlength="100"
                  :values="inputData"
                  placeholder="点検事項"
                  validation_label="点検事項"
                  validation_rules="required|max:100"
                  @onInput="onInput"
              /></Label>
              <Label label="主眼点" required>
                <InputText
                  name="main_point_add"
                  :editable="true"
                  :maxlength="100"
                  :values="inputData"
                  placeholder="主眼点"
                  validation_label="主眼点"
                  validation_rules="required|max:100"
                  @onInput="onInput"
              /></Label>
            </template>
          </AddDataSettingMachine>
        </Popup>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup
        width="300mm"
        :dialog="popups.isShowDialogTemplateOne"
        class="dialog-template"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon text @click="cloneDialogTemplate()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <TemplateTableOne
            :idMapping="{
              majorClassificationId: majorClassificationId,
              middleClassificationId: middleClassificationId,
            }"
            :imageUrl="imageUrl"
            :listPrecaution="listPrecaution"
            :listDailyItem="listDailyItem"
            :objectMapping="objectMapping"
          ></TemplateTableOne>
        </v-card>
      </Popup>
      <Popup
        width="220mm"
        :dialog="popups.isShowDialogTemplateTwo"
        class="dialog-template"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon text @click="cloneDialogTemplate()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <TemplateTableTwo
            :listMonthlyItem="listMonthlyItem"
            :objectMapping="objectMapping"
          ></TemplateTableTwo>
        </v-card>
      </Popup>
      <Popup width="500px" :dialog="popups.isShowDialogConfirm">
        <ConfirmDialog
          @close="closeDialogConfirm"
          @yes="actionDialogConfirm"
          :isDelete="true"
          :items="popupConfirm.items"
          :title="popupConfirm.titleDialog"
          :text="popupConfirm.messageDialog"
        />
      </Popup>
      <Popup width="500px" :dialog="popups.isShowDialogInfomation">
        <ConfirmDialog
          @close="popups.isShowDialogInfomation = false"
          :isInformation="true"
          :items="popupConfirm.items"
          :title="popupConfirm.titleDialog"
          :text="popupConfirm.messageDialog"
        />
      </Popup>
      <Loader :isLoading="isLoading"></Loader>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <span class="caution">※保存ボタンを押さない限り更新されません</span>
        <v-btn :disabled="actions.isEditting" color="primary" @click="submit">
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn>
      </v-toolbar>
    </template>
  </FormDialogLayout>
</template>
<script>
// Common and funtions
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import AddDataSettingMachine from "@/views/master/machines/dialog-content/AddDataSettingMachine";
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";

// Components
import Popup from "@/components/common/Popup";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";
import Label from "@/components/forms/elements/Label";
import Loader from "@/components/forms/elements/Loader";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import TabOneContent from "@/views/master/machines/dialog-content/TabOneContent";
import TabTwoContent from "@/views/master/machines/dialog-content/TabTwoContent";
import TabThreeContent from "@/views/master/machines/dialog-content/TabThreeContent";
import TemplateTableOne from "@/views/master/machines/templates/TemplateTableOne";
import TemplateTableTwo from "@/views/master/machines/templates/TemplateTableTwo";

const FORMS = {
  Image: { id: 1, title: "画像" },
  DayInspection: { id: 1, title: "作業前点検" },
  MonthInspection: { id: 1, title: "月例点検" },
};

export default {
  name: "DialogSettingMachine",
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialogLayout,
    InputText,
    SelectWithFilter,
    Label,
    Loader,
    TabOneContent,
    TabTwoContent,
    ConfirmDialog,
    TabThreeContent,
    TemplateTableOne,
    TemplateTableTwo,
    AddDataSettingMachine,
  },
  data() {
    return {
      DIALOG_BUTTON_NAMES,
      FORMS,
      valid: false,
      isLoading: false,
      tab: 0,
      popups: {
        isShowFormDialog: false,
        isShowFormDialogTabTwo: false,
        isShowDialogTemplateOne: false,
        isShowDialogTemplateTwo: false,
        isShowDialogEditMachineLevelOne: false,
        isShowDialogEditMachineLevelTwo: false,
        isShowDialogAddNew: false,
        isShowDialogConfirm: false,
        isShowDialogInfomation: false,
        isShowDialogAddMonthlyItem: false,
      },
      popupConfirm: {
        titleDialog: "点検項目削除",
        messageDialog: "点検項目を削除しますか？",
        items: [],
      },
      actions: {
        isShowDelete: true,
        isShowTemplateOne: false,
        isShowTemplateTwo: false,
        isShowAddNew: false,
        isReset: true,
        isEditting: true,
      },
      disableActions: {
        disableDelete: false,
        disableTemplateOne: false,
        disableTemplateTwo: false,
        disableAddNew: false,
        disableReset: true,
      },
      titleHeader: "月例点検項目",
      itemActive: {
        tabOne: 0,
        tabTwo: 0,
      },
      levelTab: {
        One: 1,
        Two: 2,
      },
      statusAction: {
        isMajor: false,
        deleteMajor: false,
        isMiddle: false,
        deleteInspectionDetails: false,
        deletePrecautions: false,
        deleteDailyItemItem: false,
        deletePrecautionAndDailyItem: false,
        deleteTestItem: false,
        deleteImage: false,
      },
      confirmAction: {
        major: "MAJOR",
        middle: "MIDDLE",
        deleteMajor: "DELETE_MAJOR",
        deleteInspectionDetails: "DELETE_INSPECTION_DETAILS",
        deletePrecautions: "DELETE_PRECAUTIONS",
        deleteDailyItemItem: "DELETE_DAILY_ITEM",
        deletePrecautionAndDailyItem: "DELETE_PRECAUTION_AND_DAILY_ITEM",
        deleteTestItem: "DELETE_TEST_ITEM",
        deleteImage: "DELETE_IMAGE",
      },
      idMachineEdit: null,
      listMajor: [],
      listMiddle: [],
      listPrecaution: [],
      listDailyItem: [],
      listMonthlyItem: [],
      imageUrl: null,
      isTemp: false,
      reloadCount: 0,
      majorClassificationId: null,
      middleClassificationId: null,
      testItemId: null,
      inspectionItemId: null,
      mainPointId: null,
      objectMapping: {
        majorTitle: "",
        middleTitle: "",
      },
      precautionsIds: [],
      dailyItemIds: [],
      changeValueCheckBox: {
        precautions: false,
        dailyItem: false,
        precautionAndDailyItem: false,
      },
      searchParams: {
        middle_classification: null,
        middle_classification_id: null,
        is_exits: 0,
      },
      searchInputs: {
        middle_classification: null,
      },
      tempData: {},
      inputData: {
        test_item_id: null,
        test_item_add: null,
        major_classification_add: null,
        middle_classification_add: null,
        middle_classification_furigana_add: null,
        inspection_item_add: null,
        main_point_add: null,
        middle_classification_edit: null,
        middle_classification_furigana_edit: null,
      },
    };
  },
  mounted() {
    this.initialValue();
    this.getListMajor();
    this.disableActions.disableDelete = !this.$refs.tabOneContent.hasImage;
  },
  watch: {
    tab(newIndex) {
      switch (newIndex) {
        case 0:
          this.activeTab(1);
          break;
        case 1:
          this.activeTab(2);
          break;
        case 2:
          this.activeTab(3);
          break;
      }
    },
    testItemId(newValue) {
      this.inputData.test_item_id = newValue;
    },
  },
  computed: {
    apiParams() {
      return {
        middle_classification: this.searchParams.middle_classification,
        middle_classification_id: this.searchParams.middle_classification_id,
        is_exits: this.searchParams.is_exits,
      };
    },
    getSelectListMonthlyItem() {
      let values = [];
      for (let i = 0; i < this.listMonthlyItem.length; i++) {
        let value = {};
        value.id = this.listMonthlyItem[i].test_item_id;
        value.name = this.relateText(this.listMonthlyItem[i].test_item, 25);
        values.push(value);
      }
      return values;
    },
  },
  methods: {
    updateValidate({ valid }) {
      this.valid = valid;
    },
    initialValue() {
      this.tempData = {
        major: {
          tempID: {
            name: "Temp",
            id: 0,
          },
          add: [],
          delete: [],
          edit: [],
        },
        middle: {
          tempID: {
            name: "Temp",
            id: 0,
          },
          add: [],
          delete: [],
          edit: [],
        },
        image: {
          upload: [],
          delete: [],
        },
        beforeInspection: {
          tempID: {
            name: "Temp",
            id: 0,
          },
          caution: {
            add: [],
            delete: [],
            edit: [],
          },
          contents: {
            add: [],
            delete: [],
            edit: [],
          },
        },
        monthInspection: {
          tempID: {
            name: "Temp",
            id: 0,
          },
          edit: [],
          contents: {
            add: [],
            delete: [],
          },
          details: {
            tempID: {
              name: "Temp",
              id: 0,
            },
            add: [],
            delete: [],
          },
        },
      };

      this.searchParams = {
        middle_classification: null,
        middle_classification_id: null,
        is_exits: 0,
      };
    },
    clearAddInputData() {
      this.inputData.test_item_add = null;
      this.inputData.major_classification_add = null;
      this.inputData.middle_classification_add = null;
      this.inputData.middle_classification_furigana_add = null;
      this.inputData.inspection_item_add = null;
      this.inputData.main_point_add = null;
    },
    onEditting() {
      this.actions.isEditting = false;
    },
    onEditted() {
      this.actions.isEditting = true;
    },
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.getListMajor();
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      if (value) {
        searchInputs[name] = value;
      } else {
        searchInputs[name] = null;
      }
      this.searchInputs = searchInputs;
    },
    onInput({ name, value }) {
      const inputData = { ...this.inputData };
      inputData[name] = value;
      this.inputData = inputData;
    },
    relateText(str, length = 30) {
      if (str.length <= length) {
        return str;
      }
      str = str.slice(0, length);
      return `${str}...`;
    },
    resetTabActive(type) {
      let id;
      if (type === this.levelTab.One) {
        id = this.listMajor[0]["major_classification_id"];
      }
      if (type === this.levelTab.Two) {
        id = this.listMiddle[0]["middle_classification_id"];
      }
      this.activeItem(0, type, id);
    },
    resetCheckBox() {
      this.changeValueCheckBox.precautions = false;
      this.changeValueCheckBox.dailyItem = false;
      this.changeValueCheckBox.precautionAndDailyItem = false;
    },
    resetForm() {
      document.querySelector(`form`).reset();
    },
    changeFile(hasImage) {
      this.disableActions.disableDelete = !hasImage;
      this.disableActions.disableReset = !hasImage;
      this.disableActions.disableReset =
        this.$refs.tabOneContent.getValidateStatus();
      this.uploadImage();
    },
    changeAllValueCheckBox(data) {
      this.resetCheckBox();
      this.changeValueCheckBox.precautionAndDailyItem = true;
      this.precautionsIds = data["checked_precaution_item"];
      this.dailyItemIds = data["checked_daily_item_item"];
    },
    changeValuePrecautionCheckBox(listItem) {
      this.resetCheckBox();
      this.changeValueCheckBox.precautions =
        listItem.length == 0 ? false : true;
      this.precautionsIds = listItem;
      this.disableActions.disableDelete = listItem.length <= 0;
    },
    changeValueDailyItemItemCheckBox(listItem) {
      this.resetCheckBox();
      this.changeValueCheckBox.dailyItem = listItem.length == 0 ? false : true;
      this.dailyItemIds = listItem;
      this.disableActions.disableDelete = listItem.length <= 0;
    },
    async submit() {
      this.isLoading = true;
      let emptyInspection = [];
      this.tempData.middle.add.forEach((element) => {
        let index = this.tempData.middle.delete.findIndex((item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id === element.middle_classification_id
          );
        });
        if (index < 0) {
          const addCaution = this.tempData.beforeInspection.caution.add.filter(
            (x) =>
              x.major_classification_id == element.major_classification_id &&
              x.middle_classification_id == element.middle_classification_id
          );
          const addContents =
            this.tempData.beforeInspection.contents.add.filter(
              (x) =>
                x.major_classification_id == element.major_classification_id &&
                x.middle_classification_id == element.middle_classification_id
            );
          if (addCaution.length <= 0 || addContents.length <= 0) {
            const main = this.listMajor
              .filter(
                (x) =>
                  x.major_classification_id == element.major_classification_id
              )
              .map((item) => item.major_classification);
            emptyInspection.push(...main);
          }
        }
      });
      if (emptyInspection.length > 0) {
        this.popupConfirm.items = emptyInspection;
        this.popupConfirm.titleDialog = "作業前点検確認";
        this.popupConfirm.messageDialog =
          "下記の大分類に作業前点検項目を追加してください";
        this.popups.isShowDialogInfomation = true;
        this.isLoading = false;
        return;
      }

      for (const element of this.tempData.major.delete) {
        await this.submitDeleteMajor(element);
      }

      for (const element of this.tempData.middle.delete) {
        await this.submitDeleteMiddle(element);
      }

      for (const element of this.tempData.image.delete) {
        await this.submitDeleteImage(element);
      }

      for (const element of this.tempData.beforeInspection.caution.delete) {
        await this.submitDeletePrecaution(element);
      }

      for (const element of this.tempData.beforeInspection.contents.delete) {
        await this.submitDeleteDailyItem(element);
      }

      for (const element of this.tempData.monthInspection.contents.delete) {
        await this.submitDeleteTestItem(element);
      }

      for (const element of this.tempData.monthInspection.details.delete) {
        await this.submitDeleteMonthlyItem(element);
      }

      for (const element of this.tempData.major.add) {
        await this.submitAddMajor(element);
      }

      for (const element of this.tempData.major.edit) {
        await this.submitEditMajor(element);
      }

      for (const element of this.tempData.middle.add) {
        await this.submitAddMiddle(element);
      }

      for (const element of this.tempData.middle.edit) {
        await this.submitEditMiddle(element);
      }

      for (const element of this.tempData.image.upload) {
        await this.submitAddImage(element);
      }

      for (const element of this.tempData.beforeInspection.caution.add) {
        await this.submitAddPrecaution(element);
      }

      for (const element of this.tempData.beforeInspection.caution.edit) {
        await this.submitEditPrecaution(element);
      }

      for (const element of this.tempData.beforeInspection.contents.add) {
        await this.submitAddDailyItem(element);
      }

      for (const element of this.tempData.beforeInspection.contents.edit) {
        await this.submitEditDailyItem(element);
      }

      for (const element of this.tempData.monthInspection.contents.add) {
        await this.submitAddTestItem(element);
      }

      for (const element of this.tempData.monthInspection.details.add) {
        await this.submitAddMonthlyItem(element);
      }

      for (const element of this.tempData.monthInspection.edit) {
        await this.submitEditTestItem(element);
        const promises = element.inspection_details.map((el, index) => {
          el.inspection_item_id = index + 1;
          el.main_point_id = index + 1;
          return this.submitEditMonthlyItem(element, el);
        });
        await Promise.all(promises);
      }

      this.onEditted();

      this.initialValue();

      this.getListMajor();

      this.isLoading = false;
      this.$refs.scrollMajor.scrollTop = 0;
    },
    addMajor() {
      const params = {
        major_classification_id:
          this.tempData.major.tempID.name + this.tempData.major.tempID.id,
        major_classification: this.inputData.major_classification_add,
      };
      this.listMajor.push(params);
      this.tempData.major.add.push(params);
      this.tempData.major.tempID.id = this.tempData.major.tempID.id + 1;
      this.onEditting();
      this.closeFormDialog();
    },
    async submitAddMajor(major) {
      let index = this.tempData.major.delete.findIndex((item) => {
        return item.major_classification_id === major.major_classification_id;
      });
      if (index < 0) {
        let params = {
          major_classification: major.major_classification,
        };
        const result = await Store.dispatch("Machines/addMajor", params);

        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return;
        }
        let idRes = result.data.contents.entries.major_classification_id;
        this.tempData.middle.add.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
        this.tempData.image.upload.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
        this.tempData.beforeInspection.caution.add.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
        this.tempData.beforeInspection.contents.add.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
        this.tempData.monthInspection.contents.add.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
        this.tempData.monthInspection.details.add.forEach((element) => {
          if (
            element.major_classification_id == major.major_classification_id
          ) {
            element.major_classification_id = idRes;
          }
        });
      }
    },
    editMajor() {
      let index = this.listMajor.findIndex((item) => {
        return item.major_classification_id === this.idMachineEdit;
      });
      let params = {
        major_classification_id: this.idMachineEdit,
        major_classification: this.inputData.major_classification_edit,
      };
      if (index >= 0) {
        this.listMajor[index] = params;
        if (this.idMachineEdit.toString().includes("Temp")) {
          let tempIndex = this.tempData.major.add.findIndex((item) => {
            return item.major_classification_id === this.idMachineEdit;
          });
          if (tempIndex >= 0) {
            this.tempData.major.add[tempIndex] = params;
          }
        } else {
          let tempIndex = this.tempData.major.edit.findIndex((item) => {
            return item.major_classification_id === this.idMachineEdit;
          });
          if (tempIndex >= 0) {
            this.tempData.major.edit[tempIndex] = params;
          } else {
            this.tempData.major.edit.push(params);
          }
        }
      }
      this.onEditting();
      this.closeFormDialog();
    },
    async submitEditMajor(element) {
      let index = this.tempData.major.delete.findIndex((item) => {
        return item.major_classification_id === element.major_classification_id;
      });
      if (index < 0) {
        let params = {
          major_classification_id: element.major_classification_id,
          major_classification: element.major_classification,
        };
        await Store.dispatch("Machines/editMajor", params);
      }
    },
    deleteMajor() {
      let index = this.listMajor.findIndex((item) => {
        return item.major_classification_id === this.idMachineEdit;
      });
      if (index >= 0) {
        this.$delete(this.listMajor, index);
        let params = {
          major_classification_id: this.idMachineEdit,
        };
        this.tempData.major.delete.push(params);
      }
      this.onEditting();
      this.resetTabActive(this.levelTab.One);
    },
    async submitDeleteMajor(element) {
      if (!element.major_classification_id.toString().includes("Temp")) {
        let params = {
          major_classification_id: element.major_classification_id,
        };
        await Store.dispatch("Machines/deleteMajor", params);
      }
    },
    async getListMajor() {
      const result = await Store.dispatch(
        "Machines/getListMajor",
        this.apiParams
      );
      if (result.hasError) {
        const { data } = result.response;
        console.log(data.message);
        return;
      }
      const data = result.data.contents.entries;
      if (data.length <= 0) {
        return;
      }
      this.listMajor = data;

      this.majorClassificationId = this.listMajor[0]["major_classification_id"];
      this.activeItem(0, this.levelTab.One, this.majorClassificationId);
    },
    async checkMiddleExits(middleClassification, isEdit = false) {
      this.searchParams.is_exits = true;
      this.searchParams.middle_classification = middleClassification;
      if (isEdit) {
        this.searchParams.middle_classification_id =
          this.middleClassificationId;
      } else {
        this.searchParams.middle_classification_id = null;
      }
      const result = await Store.dispatch(
        "Machines/getListMajor",
        this.apiParams
      );
      if (result.hasError) {
        const { data } = result.response;
        console.log(data.message);
        return false;
      }
      const data = result.data.contents.entries;
      const majorTempListId = this.tempData.middle.add.filter((x) => {
        return (
          x.middle_classification == middleClassification &&
          x.middle_classification_id != this.middleClassificationId
        );
      });
      this.tempData.middle.delete.forEach((element) => {
        let index = data.findIndex((item) => {
          return (
            item.major_classification_id == element.major_classification_id
          );
        });
        if (index >= 0) {
          this.$delete(data, index);
        }
        let tempIdx = majorTempListId.findIndex((item) => {
          return (
            item.major_classification_id == element.major_classification_id &&
            item.middle_classification_id == element.middle_classification_id
          );
        });
        if (tempIdx >= 0) {
          this.$delete(majorTempListId, tempIdx);
        }
      });
      if (data && data.length > 0) {
        const items = data.map((item) => item.major_classification);
        this.popupConfirm.items = items;
        this.popupConfirm.titleDialog = "機械名重複確認";
        this.popupConfirm.messageDialog = "下記の大分類に同名の機械があります";
        this.popups.isShowDialogInfomation = true;
        return false;
      }
      if (majorTempListId && majorTempListId.length > 0) {
        let tempList = [];
        majorTempListId.forEach((item) => {
          const temp = this.tempData.major.add
            .filter(
              (x) => x.major_classification_id == item.major_classification_id
            )
            .map((item) => item.major_classification);
          const main = this.listMajor
            .filter(
              (x) => x.major_classification_id == item.major_classification_id
            )
            .map((item) => item.major_classification);
          if (temp && temp.length > 0) {
            tempList.push(temp[0]);
          } else if (main && main.length > 0) {
            tempList.push(main[0]);
          }
        });
        this.popupConfirm.items = tempList;
        this.popupConfirm.titleDialog = "機械名重複確認";
        this.popupConfirm.messageDialog = "下記の大分類に同名の機械があります";
        this.popups.isShowDialogInfomation = true;
        return false;
      }
      return true;
    },
    async addMiddle() {
      const checkResult = await this.checkMiddleExits(
        this.inputData.middle_classification_add
      );
      if (!checkResult) return;
      let params = {
        major_classification_id: this.majorClassificationId,
        middle_classification_id:
          this.tempData.middle.tempID.name + this.tempData.middle.tempID.id,
        middle_classification: this.inputData.middle_classification_add,
        middle_classification_furigana:
          this.inputData.middle_classification_furigana_add,
      };
      this.listMiddle.unshift(params);
      this.tempData.middle.add.push(params);
      this.middleClassificationId = params.middle_classification_id;
      this.objectMapping.middleTitle = params.middle_classification;
      this.getListMiddle(this.majorClassificationId);
      this.tempData.middle.tempID.id = this.tempData.middle.tempID.id + 1;
      this.onEditting();
      this.closeFormDialog();
    },
    async submitAddMiddle(middle) {
      let index = this.tempData.middle.delete.findIndex((item) => {
        return (
          item.major_classification_id === middle.major_classification_id &&
          item.middle_classification_id === middle.middle_classification_id
        );
      });
      if (index < 0) {
        let params = {
          major_classification_id: middle.major_classification_id,
          middle_classification: middle.middle_classification,
          middle_classification_furigana: middle.middle_classification_furigana,
        };
        const result = await Store.dispatch("Machines/addMiddle", params);

        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return false;
        }
        let idRes = result.data.contents.entries.middle_classification_id;
        this.tempData.image.upload.forEach((element) => {
          if (
            element.major_classification_id == middle.major_classification_id &&
            element.middle_classification_id == middle.middle_classification_id
          ) {
            element.middle_classification_id = idRes;
          }
        });
        this.tempData.beforeInspection.caution.add.forEach((element) => {
          if (
            element.major_classification_id == middle.major_classification_id &&
            element.middle_classification_id == middle.middle_classification_id
          ) {
            element.middle_classification_id = idRes;
          }
        });
        this.tempData.beforeInspection.contents.add.forEach((element) => {
          if (
            element.major_classification_id == middle.major_classification_id &&
            element.middle_classification_id == middle.middle_classification_id
          ) {
            element.middle_classification_id = idRes;
          }
        });
        this.tempData.monthInspection.contents.add.forEach((element) => {
          if (
            element.major_classification_id == middle.major_classification_id &&
            element.middle_classification_id == middle.middle_classification_id
          ) {
            element.middle_classification_id = idRes;
          }
        });
        this.tempData.monthInspection.details.add.forEach((element) => {
          if (
            element.major_classification_id == middle.major_classification_id &&
            element.middle_classification_id == middle.middle_classification_id
          ) {
            element.middle_classification_id = idRes;
          }
        });
      }
    },
    async editMiddle() {
      const checkResult = await this.checkMiddleExits(
        this.inputData.middle_classification_edit,
        true
      );
      if (!checkResult) return;
      let index = this.listMiddle.findIndex((item) => {
        return (
          item.major_classification_id === this.majorClassificationId &&
          item.middle_classification_id === this.middleClassificationId
        );
      });
      let params = {
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        middle_classification: this.inputData.middle_classification_edit,
        middle_classification_furigana:
          this.inputData.middle_classification_furigana_edit,
      };
      if (index >= 0) {
        this.listMiddle[index] = params;
        if (this.middleClassificationId.toString().includes("Temp")) {
          let tempIndex = this.tempData.middle.add.findIndex((item) => {
            return (
              item.major_classification_id == this.majorClassificationId &&
              item.middle_classification_id == this.middleClassificationId
            );
          });
          if (tempIndex >= 0) {
            this.tempData.middle.add[tempIndex] = params;
          }
        } else {
          let tempIndex = this.tempData.middle.edit.findIndex((item) => {
            return (
              item.major_classification_id == this.majorClassificationId &&
              item.middle_classification_id == this.middleClassificationId
            );
          });
          if (tempIndex >= 0) {
            this.tempData.middle.edit[tempIndex] = params;
          } else {
            this.tempData.middle.edit.push(params);
          }
        }
      }
      this.onEditting();
      this.closeFormDialog();
    },
    async submitEditMiddle(element) {
      let index = this.tempData.middle.delete.findIndex((item) => {
        return (
          item.major_classification_id === element.major_classification_id &&
          item.middle_classification_id === element.middle_classification_id
        );
      });
      if (index < 0) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          middle_classification: element.middle_classification,
          middle_classification_furigana:
            element.middle_classification_furigana,
        };
        await Store.dispatch("Machines/editMiddle", params);
      }
    },
    copyMiddle(middle_classification_id) {
      if (middle_classification_id.toString().includes("Temp")) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "登録していない為、コピーできません。",
        });
        return;
      }
      this.middleClassificationId = middle_classification_id;

      let params = {
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
      };

      Store.dispatch("Machines/copyMiddle", params)
        .then((response) => {
          if (response.data.status_code == 200) {
            let index = this.listMiddle.length;
            this.activeItem(index, this.levelTab.Two, middle_classification_id);
            this.getListMiddle(this.majorClassificationId, index);
            this.closeFormDialog();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteMiddle() {
      let index = this.listMiddle.findIndex((item) => {
        return (
          item.major_classification_id === this.majorClassificationId &&
          item.middle_classification_id === this.middleClassificationId
        );
      });
      if (index >= 0) {
        this.$delete(this.listMiddle, index);
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
        };
        this.tempData.middle.delete.push(params);
      }
      this.onEditting();
      this.closeFormDialog();
      if (this.listMiddle.length === 0) {
        this.objectMapping.middleTitle = "";
      } else {
        this.resetTabActive(this.levelTab.Two);
      }
    },
    async submitDeleteMiddle(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp")
      ) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
        };
        await Store.dispatch("Machines/deleteMiddle", params);
      }
    },
    async getListMiddle(major_id, index = 0) {
      const tempAddList = this.tempData.middle.add.filter((x) => {
        return x.major_classification_id == major_id;
      });
      const tempEditList = this.tempData.middle.edit.filter((x) => {
        return x.major_classification_id == major_id;
      });
      if (major_id.toString().includes("Temp")) {
        this.listMiddle = tempAddList;
        this.tempData.middle.delete.forEach((element) => {
          let index = this.listMiddle.findIndex((item) => {
            return (
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id === element.middle_classification_id
            );
          });
          if (index >= 0) {
            this.$delete(this.listMiddle, index);
          }
        });
        if (tempAddList && tempAddList.length > 0) {
          this.middleClassificationId =
            this.listMiddle[index]["middle_classification_id"];
          this.objectMapping.middleTitle =
            this.listMiddle[index]["middle_classification"];
          this.getImageUrl();
        }
        return;
      }
      let params = {
        major_classification_id: major_id,
      };
      const result = await Store.dispatch("Machines/getListMiddle", { params });

      if (result.hasError) {
        const { data } = result.response;
        console.log(data.message);
        return;
      }
      this.listMiddle = result.data.contents.entries;
      if (tempEditList && tempEditList.length > 0) {
        tempEditList.forEach((element) => {
          let idx = this.listMiddle.findIndex((item) => {
            return (
              item.major_classification_id == element.major_classification_id &&
              item.middle_classification_id == element.middle_classification_id
            );
          });
          if (idx >= 0) {
            this.listMiddle[idx] = element;
          }
        });
      }
      if (tempAddList && tempAddList.length > 0) {
        this.listMiddle.unshift(...tempAddList);
      }
      this.tempData.middle.delete.forEach((element) => {
        let index = this.listMiddle.findIndex((item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id === element.middle_classification_id
          );
        });
        if (index >= 0) {
          this.$delete(this.listMiddle, index);
        }
      });
      if (result.data.contents.entries.length !== 0) {
        this.middleClassificationId =
          this.listMiddle[index]["middle_classification_id"];
        this.objectMapping.middleTitle =
          this.listMiddle[index]["middle_classification"];
        this.getImageUrl();
      }
    },
    getListPrecaution() {
      const tempAddList = this.tempData.beforeInspection.caution.add.filter(
        (x) => {
          return (
            x.major_classification_id === this.majorClassificationId &&
            x.middle_classification_id === this.middleClassificationId
          );
        }
      );
      const tempEditList = this.tempData.beforeInspection.caution.edit.filter(
        (x) => {
          return (
            x.major_classification_id === this.majorClassificationId &&
            x.middle_classification_id === this.middleClassificationId
          );
        }
      );
      if (
        this.majorClassificationId.toString().includes("Temp") ||
        this.middleClassificationId.toString().includes("Temp")
      ) {
        this.listPrecaution = tempAddList;
        this.tempData.beforeInspection.caution.delete.forEach((element) => {
          let index = this.listPrecaution.findIndex((item) => {
            return (
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id === element.middle_classification_id
            );
          });
          if (index >= 0) {
            this.$delete(this.listPrecaution, index);
          }
        });
        return;
      }
      let count = this.listMiddle.filter((x) => {
        return x.middle_classification_id === this.middleClassificationId;
      }).length;
      if (count > 0) {
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
        };
        Store.dispatch("Machines/getListPrecaution", { params })
          .then((response) => {
            this.listPrecaution = response.data.contents.entries;
            if (tempEditList && tempEditList.length > 0) {
              tempEditList.forEach((element) => {
                let index = this.listPrecaution.findIndex((item) => {
                  return (
                    item.major_classification_id ==
                      element.major_classification_id &&
                    item.middle_classification_id ==
                      element.middle_classification_id &&
                    item.precautions_id == element.precautions_id
                  );
                });
                if (index >= 0) {
                  this.listPrecaution[index] = element;
                }
              });
            }
            if (tempAddList && tempAddList.length > 0) {
              this.listPrecaution = [...this.listPrecaution, ...tempAddList];
            }
            this.tempData.beforeInspection.caution.delete.forEach((element) => {
              let index = this.listPrecaution.findIndex((item) => {
                return (
                  item.major_classification_id ===
                    element.major_classification_id &&
                  item.middle_classification_id ===
                    element.middle_classification_id
                );
              });
              if (index >= 0) {
                this.$delete(this.listPrecaution, index);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addPrecaution(data) {
      let params = {
        id:
          this.tempData.beforeInspection.tempID.name +
          this.tempData.beforeInspection.tempID.id,
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        precautions_id:
          this.tempData.beforeInspection.tempID.name +
          this.tempData.beforeInspection.tempID.id,
        precautions: data.precautions_add,
      };
      this.listPrecaution.push(params);
      this.tempData.beforeInspection.caution.add.push(params);
      this.tempData.beforeInspection.tempID.id =
        this.tempData.beforeInspection.tempID.id + 1;
      this.resetForm();
      this.onEditting();
    },
    async submitAddPrecaution(caution) {
      let index = this.tempData.beforeInspection.caution.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === caution.major_classification_id &&
            item.middle_classification_id ===
              caution.middle_classification_id &&
            item.precautions_id === caution.precautions_id
          );
        }
      );
      if (index < 0) {
        let params = {
          major_classification_id: caution.major_classification_id,
          middle_classification_id: caution.middle_classification_id,
          precautions: caution.precautions,
        };
        const result = await Store.dispatch("Machines/addPrecaution", params);
        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return;
        }
        let idRes = result.data.contents.entries.id;
        this.tempData.beforeInspection.caution.edit.forEach((element) => {
          if (element.id == caution.id) {
            element.id = idRes;
          }
        });
      }
    },
    editPrecaution(listPrecaution) {
      this.listPrecaution = listPrecaution;
      this.listPrecaution.forEach((element) => {
        let index = this.tempData.beforeInspection.caution.edit.findIndex(
          (item) => {
            return (
              item.id === element.id &&
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id ===
                element.middle_classification_id &&
              item.precautions_id === element.precautions_id
            );
          }
        );
        if (index >= 0) {
          this.tempData.beforeInspection.caution.edit[index].precautions =
            element.precautions;
        } else {
          this.tempData.beforeInspection.caution.edit.push(element);
        }
      });
      const sorted = this.tempData.beforeInspection.caution.edit.sort(
        (a, b) => {
          if (a.major_classification_id === b.major_classification_id) {
            return a.middle_classification_id - b.middle_classification_id;
          }
          return a.major_classification_id - b.major_classification_id;
        }
      );
      let count = 1;
      let major_classification_id = null;
      let middle_classification_id = null;
      sorted.forEach((element) => {
        if (
          major_classification_id != element.major_classification_id ||
          middle_classification_id != element.middle_classification_id
        ) {
          count = 1;
          major_classification_id = element.major_classification_id;
          middle_classification_id = element.middle_classification_id;
        }
        element.precautions_id = count;
        count++;
      });
      this.tempData.beforeInspection.caution.edit = sorted;
      this.onEditting();
    },
    async submitEditPrecaution(element) {
      let index = this.tempData.beforeInspection.caution.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id ===
              element.middle_classification_id &&
            item.precautions_id === element.precautions_id
          );
        }
      );
      if (index < 0) {
        let params = {
          id: element.id,
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          precautions_id: element.precautions_id,
          precautions: element.precautions,
        };
        await Store.dispatch("Machines/editPrecaution", params);
      }
    },
    deletePrecaution() {
      if (this.precautionsIds.length >= this.listPrecaution.length) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "注意事項は1件以上が必要です。",
        });
        this.resetForm();
        this.$refs.tabTwoContent.resetCheckBox();
        this.disableActions.disableDelete = true;
        this.precautionsIds = [];
        this.activeTab(2);
        return;
      }
      if (this.precautionsIds.length > 0) {
        this.precautionsIds.forEach((element) => {
          let index = this.listPrecaution.findIndex((item) => {
            return (
              item.major_classification_id == this.majorClassificationId &&
              item.middle_classification_id == this.middleClassificationId &&
              item.precautions_id == element
            );
          });
          if (index >= 0) {
            this.$delete(this.listPrecaution, index);
          }
        });
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
          precautions_ids: this.precautionsIds,
        };
        this.tempData.beforeInspection.caution.delete.push(params);
        this.resetForm();
        this.$refs.tabTwoContent.resetCheckBox();
        this.disableActions.disableDelete = true;
        this.precautionsIds = [];
        this.onEditting();
      }
    },
    async submitDeletePrecaution(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp")
      ) {
        for (const el of element.precautions_ids) {
          if (!el.toString().includes("Temp")) {
            let params = {
              major_classification_id: element.major_classification_id,
              middle_classification_id: element.middle_classification_id,
              precautions_ids: [el],
            };
            await Store.dispatch("Machines/deletePrecaution", params);
          }
        }
      }
    },
    addDailyItem(data) {
      let params = {
        id:
          this.tempData.beforeInspection.tempID.name +
          this.tempData.beforeInspection.tempID.id,
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        inspection_item_id:
          this.tempData.beforeInspection.tempID.name +
          this.tempData.beforeInspection.tempID.id,
        inspection_item: data.inspection_item_add,
        main_point_id:
          this.tempData.beforeInspection.tempID.name +
          this.tempData.beforeInspection.tempID.id,
        main_point: data.main_point_add,
      };
      this.listDailyItem.push(params);
      this.tempData.beforeInspection.contents.add.push(params);
      this.resetForm();
      this.onEditting();
    },
    async submitAddDailyItem(daylyItem) {
      let index = this.tempData.beforeInspection.contents.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id ===
              daylyItem.major_classification_id &&
            item.middle_classification_id ===
              daylyItem.middle_classification_id &&
            item.inspection_item_id === daylyItem.inspection_item_id &&
            item.main_point_id === daylyItem.main_point_id
          );
        }
      );
      if (index < 0) {
        let params = {
          major_classification_id: daylyItem.major_classification_id,
          middle_classification_id: daylyItem.middle_classification_id,
          inspection_item: daylyItem.inspection_item,
          main_point: daylyItem.main_point,
        };
        const result = await Store.dispatch("Machines/addDailyItem", params);
        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return;
        }
        let idRes = result.data.contents.entries.id;
        this.tempData.beforeInspection.contents.edit.forEach((element) => {
          if (element.id == daylyItem.id) {
            element.id = idRes;
          }
        });
      }
    },
    editDailyItem(listDailyItem) {
      this.listDailyItem = listDailyItem;
      this.listDailyItem.forEach((element) => {
        let index = this.tempData.beforeInspection.contents.edit.findIndex(
          (item) => {
            return (
              item.id === element.id &&
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id ===
                element.middle_classification_id &&
              item.inspection_item_id === element.inspection_item_id &&
              item.main_point_id === element.main_point_id
            );
          }
        );
        if (index >= 0) {
          this.tempData.beforeInspection.contents.edit[index].inspection_item =
            element.inspection_item;
          this.tempData.beforeInspection.contents.edit[index].main_point =
            element.main_point;
        } else {
          this.tempData.beforeInspection.contents.edit.push(element);
        }
      });
      const sorted = this.tempData.beforeInspection.contents.edit.sort(
        (a, b) => {
          if (a.major_classification_id === b.major_classification_id) {
            return a.middle_classification_id - b.middle_classification_id;
          }
          return a.major_classification_id - b.major_classification_id;
        }
      );
      let count = 1;
      let major_classification_id = null;
      let middle_classification_id = null;
      sorted.forEach((element) => {
        if (
          major_classification_id != element.major_classification_id ||
          middle_classification_id != element.middle_classification_id
        ) {
          count = 1;
          major_classification_id = element.major_classification_id;
          middle_classification_id = element.middle_classification_id;
        }
        element.inspection_item_id = count;
        element.main_point_id = count;
        count++;
      });
      this.tempData.beforeInspection.contents.edit = sorted;
      this.onEditting();
    },
    async submitEditDailyItem(element) {
      let index = this.tempData.beforeInspection.caution.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id ===
              element.middle_classification_id &&
            item.inspection_item_id === element.inspection_item_id &&
            item.main_point_id === element.main_point_id
          );
        }
      );
      if (index < 0) {
        let params = {
          id: element.id,
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          inspection_item_id: element.inspection_item_id,
          inspection_item: element.inspection_item,
          main_point_id: element.main_point_id,
          main_point: element.main_point,
        };
        await Store.dispatch("Machines/editDailyItem", params);
      }
    },
    deleteDailyItem() {
      if (this.precautionsIds.length >= this.listPrecaution.length) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "点検項目は1件以上が必要です。",
        });
        this.resetForm();
        this.$refs.tabTwoContent.resetCheckBox();
        this.disableActions.disableDelete = true;
        this.dailyItemIds = [];
        this.activeTab(2);
        return;
      }
      if (this.dailyItemIds.length > 0) {
        this.dailyItemIds.forEach((element) => {
          let index = this.listDailyItem.findIndex((item) => {
            return (
              item.major_classification_id == this.majorClassificationId &&
              item.middle_classification_id == this.middleClassificationId &&
              item.inspection_item_id == element.inspection_item_id &&
              item.main_point_id == element.main_point_id
            );
          });
          if (index >= 0) {
            this.$delete(this.listDailyItem, index);
          }
        });
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
          daily_item_ids: this.dailyItemIds,
        };
        this.tempData.beforeInspection.contents.delete.push(params);
        this.resetForm();
        this.$refs.tabTwoContent.resetCheckBox();
        this.disableActions.disableDelete = true;
        this.dailyItemIds = [];
        this.onEditting();
      }
    },
    async submitDeleteDailyItem(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp")
      ) {
        for (const el of element.daily_item_ids) {
          if (
            !el.inspection_item_id.toString().includes("Temp") &&
            !el.main_point_id.toString().includes("Temp")
          ) {
            let params = {
              major_classification_id: element.major_classification_id,
              middle_classification_id: element.middle_classification_id,
              daily_item_ids: [el],
            };
            await Store.dispatch("Machines/deleteDailyItem", params);
          }
        }
      }
    },
    getListDailyItem() {
      const tempAddList = this.tempData.beforeInspection.contents.add.filter(
        (x) => {
          return (
            x.major_classification_id === this.majorClassificationId &&
            x.middle_classification_id === this.middleClassificationId
          );
        }
      );
      const tempEditList = this.tempData.beforeInspection.contents.edit.filter(
        (x) => {
          return (
            x.major_classification_id === this.majorClassificationId &&
            x.middle_classification_id === this.middleClassificationId
          );
        }
      );
      if (
        this.majorClassificationId.toString().includes("Temp") ||
        this.middleClassificationId.toString().includes("Temp")
      ) {
        this.listDailyItem = tempAddList;
        this.tempData.beforeInspection.contents.delete.forEach((element) => {
          let index = this.listDailyItem.findIndex((item) => {
            return (
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id === element.middle_classification_id
            );
          });
          if (index >= 0) {
            this.$delete(this.listDailyItem, index);
          }
        });
        return;
      }
      let count = this.listMiddle.filter((x) => {
        return x.middle_classification_id === this.middleClassificationId;
      }).length;
      if (count > 0) {
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
        };
        Store.dispatch("Machines/getListDailyItem", { params })
          .then((response) => {
            this.listDailyItem = response.data.contents.entries;
            if (tempEditList && tempEditList.length > 0) {
              tempEditList.forEach((element) => {
                let index = this.listDailyItem.findIndex((item) => {
                  return (
                    item.major_classification_id ==
                      element.major_classification_id &&
                    item.middle_classification_id ==
                      element.middle_classification_id &&
                    item.inspection_item_id == element.inspection_item_id &&
                    item.main_point_id == element.main_point_id
                  );
                });
                if (index >= 0) {
                  this.listDailyItem[index] = element;
                }
              });
            }
            if (tempAddList && tempAddList.length > 0) {
              this.listDailyItem = [...this.listDailyItem, ...tempAddList];
            }
            this.tempData.beforeInspection.contents.delete.forEach(
              (element) => {
                let index = this.listDailyItem.findIndex((item) => {
                  return (
                    item.major_classification_id ===
                      element.major_classification_id &&
                    item.middle_classification_id ===
                      element.middle_classification_id
                  );
                });
                if (index >= 0) {
                  this.$delete(this.listDailyItem, index);
                }
              }
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getListMonthlyItem() {
      const tempAddList = this.tempData.monthInspection.contents.add.filter(
        (x) => {
          return (
            x.major_classification_id === this.majorClassificationId &&
            x.middle_classification_id === this.middleClassificationId
          );
        }
      );
      const tempEditList = this.tempData.monthInspection.edit.filter((x) => {
        return (
          x.major_classification_id === this.majorClassificationId &&
          x.middle_classification_id === this.middleClassificationId
        );
      });
      if (
        this.majorClassificationId.toString().includes("Temp") ||
        this.middleClassificationId.toString().includes("Temp")
      ) {
        this.listMonthlyItem = tempAddList;
        this.tempData.monthInspection.contents.delete.forEach((element) => {
          let index = this.listMonthlyItem.findIndex((item) => {
            return (
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id ===
                element.middle_classification_id &&
              item.test_item_id === element.test_item_id
            );
          });
          if (index >= 0) {
            this.$delete(this.listMonthlyItem, index);
          }
        });
        this.tempData.monthInspection.details.delete.forEach((element) => {
          let index = this.listMonthlyItem.findIndex((item) => {
            return (
              item.major_classification_id ===
                element.major_classification_id &&
              item.middle_classification_id ===
                element.middle_classification_id &&
              item.test_item_id === element.test_item_id
            );
          });
          if (index >= 0) {
            let detailIdx = this.listMonthlyItem[
              index
            ].inspection_details.findIndex((item) => {
              return (
                item.inspection_item_id === element.inspection_item_id &&
                item.main_point_id === element.main_point_id
              );
            });
            if (detailIdx >= 0)
              this.$delete(
                this.listMonthlyItem[index].inspection_details,
                detailIdx
              );
          }
        });
        return;
      }
      let count = this.listMiddle.filter((x) => {
        return x.middle_classification_id === this.middleClassificationId;
      }).length;
      if (count > 0) {
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
        };
        Store.dispatch("Machines/getListMonthlyItem", { params })
          .then((response) => {
            this.listMonthlyItem = response.data.contents.entries;
            if (tempEditList && tempEditList.length > 0) {
              tempEditList.forEach((element) => {
                let index = this.listMonthlyItem.findIndex((item) => {
                  return (
                    item.major_classification_id ==
                      element.major_classification_id &&
                    item.middle_classification_id ==
                      element.middle_classification_id &&
                    item.test_item_id == element.test_item_id
                  );
                });
                if (index >= 0) {
                  this.listMonthlyItem[index] = element;
                }
              });
            }

            if (tempAddList && tempAddList.length > 0) {
              this.listMonthlyItem = [...this.listMonthlyItem, ...tempAddList];
            }
            this.tempData.monthInspection.contents.delete.forEach((element) => {
              let index = this.listMonthlyItem.findIndex((item) => {
                return (
                  item.major_classification_id ===
                    element.major_classification_id &&
                  item.middle_classification_id ===
                    element.middle_classification_id &&
                  item.test_item_id === element.test_item_id
                );
              });
              if (index >= 0) {
                this.$delete(this.listMonthlyItem, index);
              }
            });
            this.tempData.monthInspection.details.delete.forEach((element) => {
              let index = this.listMonthlyItem.findIndex((item) => {
                return (
                  item.major_classification_id ===
                    element.major_classification_id &&
                  item.middle_classification_id ===
                    element.middle_classification_id &&
                  item.test_item_id === element.test_item_id
                );
              });
              if (index >= 0) {
                let detailIdx = this.listMonthlyItem[
                  index
                ].inspection_details.findIndex((item) => {
                  return (
                    item.inspection_item_id === element.inspection_item_id &&
                    item.main_point_id === element.main_point_id
                  );
                });
                if (detailIdx >= 0)
                  this.$delete(
                    this.listMonthlyItem[index].inspection_details,
                    detailIdx
                  );
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getImageUrl() {
      this.reloadCount++;
      let deleteIdx = this.tempData.image.delete.findIndex((item) => {
        return (
          item.major_classification_id == this.majorClassificationId &&
          item.middle_classification_id == this.middleClassificationId
        );
      });
      if (deleteIdx >= 0) {
        this.imageUrl = null;
        this.disableActions.disableDelete = true;
        this.isTemp = true;
        return;
      }
      let uploadIdx = this.tempData.image.upload.findIndex((item) => {
        return (
          item.major_classification_id == this.majorClassificationId &&
          item.middle_classification_id == this.middleClassificationId
        );
      });
      if (uploadIdx >= 0) {
        const fileUrl = this.tempData.image.upload[uploadIdx].image;
        this.imageUrl = fileUrl;
        this.disableActions.disableDelete =
          this.imageUrl == null || this.imageUrl == undefined;
        this.isTemp = true;
        return;
      }
      if (
        this.majorClassificationId.toString().includes("Temp") ||
        this.middleClassificationId.toString().includes("Temp")
      ) {
        this.imageUrl = null;
        this.disableActions.disableDelete = true;
        this.isTemp = true;
        return;
      }
      let count = this.listMiddle.filter((x) => {
        return x.middle_classification_id === this.middleClassificationId;
      }).length;
      if (count > 0) {
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
        };
        Store.dispatch("Machines/getImageUrl", { params })
          .then((response) => {
            if (response.data.status_code == 200) {
              this.imageUrl = response.data.contents.image_url;
              this.disableActions.disableDelete = this.imageUrl === null;
              this.isTemp = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.disableActions.disableDelete = true;
      }
    },
    uploadImage() {
      this.$refs.tabOneContent.setLoading(true);
      let dataFile = this.$refs.tabOneContent.getInfoFile();
      let params = {
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        image_name: dataFile.fileName,
        image: dataFile.fileBase64,
      };
      this.tempData.image.upload.push(params);
      this.$refs.tabOneContent.cleanImage();
      this.$refs.tabOneContent.setLoading(false);
      this.disableActions.disableReset = true;
      this.onEditting();
    },
    async submitAddImage(element) {
      let index = this.tempData.image.delete.findIndex((item) => {
        return (
          item.major_classification_id === element.major_classification_id &&
          item.middle_classification_id === element.middle_classification_id
        );
      });
      if (index < 0) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          image_name: element.image_name,
          image: element.image,
        };
        await Store.dispatch("Machines/uploadImage", params);
      }
    },
    deleteImage() {
      let params = {
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
      };
      this.tempData.image.delete.push(params);
      this.disableActions.disableDelete = true;
      this.imageUrl = null;
      this.onEditting();
    },
    async submitDeleteImage(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp")
      ) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
        };
        await Store.dispatch("Machines/deleteImage", params);
      }
    },
    addTestItem() {
      let params = {
        id:
          this.tempData.monthInspection.tempID.name +
          this.tempData.monthInspection.tempID.id,
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        test_item_id:
          this.tempData.monthInspection.tempID.name +
          this.tempData.monthInspection.tempID.id,
        test_item: this.inputData.test_item_add,
        inspection_details: [],
      };
      this.listMonthlyItem.push(params);
      this.tempData.monthInspection.contents.add.push(params);
      this.tempData.monthInspection.tempID.id =
        this.tempData.monthInspection.tempID.id + 1;
      this.onEditting();
      this.closeFormDialog();
    },
    async submitAddTestItem(testItem) {
      let index = this.tempData.monthInspection.contents.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === testItem.major_classification_id &&
            item.middle_classification_id ===
              testItem.middle_classification_id &&
            item.test_item_id === testItem.test_item_id
          );
        }
      );
      if (index < 0) {
        let params = {
          major_classification_id: testItem.major_classification_id,
          middle_classification_id: testItem.middle_classification_id,
          test_item: testItem.test_item,
        };
        const result = await Store.dispatch("Machines/addTestItem", params);

        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return;
        }
        let idRes = result.data.contents.entries;
        this.tempData.monthInspection.details.add.forEach((element) => {
          if (
            element.major_classification_id ==
              testItem.major_classification_id &&
            element.middle_classification_id ==
              testItem.middle_classification_id &&
            element.test_item_id == testItem.test_item_id
          ) {
            element.test_item_id = idRes;
          }
        });
      }
    },
    async submitEditTestItem(element) {
      let index = this.tempData.monthInspection.contents.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id ===
              element.middle_classification_id &&
            item.test_item_id === element.test_item_id
          );
        }
      );
      if (index < 0) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          test_item_id: element.test_item_id,
          test_item: element.test_item,
        };
        await Store.dispatch("Machines/editTestItem", params);
      }
    },
    deleteTestItem() {
      let index = this.listMonthlyItem.findIndex((item) => {
        return (
          item.major_classification_id === this.majorClassificationId &&
          item.middle_classification_id === this.middleClassificationId &&
          item.test_item_id === this.testItemId
        );
      });
      if (index >= 0) {
        this.$delete(this.listMonthlyItem, index);
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
          test_item_id: this.testItemId,
        };
        this.tempData.monthInspection.contents.delete.push(params);
      }
      this.onEditting();
      this.closeFormDialog();
    },
    async submitDeleteTestItem(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp") &&
        !element.test_item_id.toString().includes("Temp")
      ) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          test_item_id: element.test_item_id,
        };
        await Store.dispatch("Machines/deleteTestItem", params);
      }
    },
    addMonthlyItem(isContinue = false) {
      let testItemId = this.inputData.test_item_id;
      let inspection_item_add = this.inputData.inspection_item_add;
      let main_point_add = this.inputData.main_point_add;
      let index = this.listMonthlyItem.findIndex((item) => {
        return (
          item.major_classification_id == this.majorClassificationId &&
          item.middle_classification_id == this.middleClassificationId &&
          item.test_item_id == testItemId
        );
      });
      let params = {
        id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        inspection_item_id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        inspection_item: inspection_item_add,
        main_point_id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        main_point: main_point_add,
      };
      let copy = this.listMonthlyItem;
      copy[index].inspection_details.push(params);
      this.listMonthlyItem = copy;
      let tempParams = {
        id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        major_classification_id: this.majorClassificationId,
        middle_classification_id: this.middleClassificationId,
        test_item_id: testItemId,
        inspection_item_id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        inspection_item: inspection_item_add,
        main_point_id:
          this.tempData.monthInspection.details.tempID.name +
          this.tempData.monthInspection.details.tempID.id,
        main_point: main_point_add,
      };
      this.tempData.monthInspection.details.tempID.id =
        this.tempData.monthInspection.details.tempID.id + 1;
      this.tempData.monthInspection.details.add.push(tempParams);
      if (isContinue) {
        this.inputData.test_item_id = this.testItemId;
        this.inputData.inspection_item_add = null;
        this.inputData.main_point_add = null;
      } else {
        this.closeFormDialog();
      }
      this.onEditting();
    },
    async submitAddMonthlyItem(detailItem) {
      let index = this.tempData.monthInspection.details.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id ==
              detailItem.major_classification_id &&
            item.middle_classification_id ==
              detailItem.middle_classification_id &&
            item.test_item_id == detailItem.test_item_id &&
            item.inspection_item_id == detailItem.inspection_item_id &&
            item.main_point_id == detailItem.main_point_id
          );
        }
      );
      if (index < 0) {
        let params = {
          major_classification_id: detailItem.major_classification_id,
          middle_classification_id: detailItem.middle_classification_id,
          test_item_id: detailItem.test_item_id,
          inspection_item: detailItem.inspection_item,
          main_point: detailItem.main_point,
        };
        const result = await Store.dispatch("Machines/addMonthlyItem", params);
        if (result.hasError) {
          const { data } = result.response;
          console.log(data.message);
          return;
        }
        let idRes = result.data.contents.entries.id;
        let detailIdx = this.tempData.monthInspection.edit.findIndex((item) => {
          return (
            item.major_classification_id ==
              detailItem.major_classification_id &&
            item.middle_classification_id ==
              detailItem.middle_classification_id &&
            item.test_item_id == detailItem.test_item_id
          );
        });
        if (detailIdx >= 0) {
          this.tempData.monthInspection.edit[
            detailIdx
          ].inspection_details.forEach((element) => {
            if (
              element.id.toString().includes("Temp") &&
              element.inspection_item_id == detailItem.inspection_item_id &&
              element.main_point_id == detailItem.main_point_id
            ) {
              element.id = idRes;
            }
          });
        }
      }
    },
    editMonthlyItem(listMonthlyItem, updateItem) {
      this.listMonthlyItem = listMonthlyItem;
      let index = this.tempData.monthInspection.edit.findIndex((item) => {
        return (
          item.major_classification_id === updateItem.major_classification_id &&
          item.middle_classification_id ===
            updateItem.middle_classification_id &&
          item.test_item_id === updateItem.test_item_id
        );
      });
      if (index >= 0) {
        this.tempData.monthInspection.edit[index] = updateItem;
      } else {
        this.tempData.monthInspection.edit.push(updateItem);
      }
      this.onEditting();
    },
    async submitEditMonthlyItem(element, el) {
      let index = this.tempData.monthInspection.details.delete.findIndex(
        (item) => {
          return (
            item.major_classification_id === element.major_classification_id &&
            item.middle_classification_id ===
              element.middle_classification_id &&
            item.test_item_id === element.test_item_id &&
            item.inspection_item_id === el.inspection_item_id &&
            item.main_point_id === el.main_point_id
          );
        }
      );
      if (index < 0) {
        let params = {
          id: el.id,
          inspection_item_id: el.inspection_item_id,
          inspection_item: el.inspection_item,
          main_point_id: el.main_point_id,
          main_point: el.main_point,
        };
        await Store.dispatch("Machines/editMonthlyItem", params);
      }
    },
    deleteMonthlyItem() {
      let index = this.listMonthlyItem.findIndex((item) => {
        return (
          item.major_classification_id === this.majorClassificationId &&
          item.middle_classification_id === this.middleClassificationId &&
          item.test_item_id === this.testItemId
        );
      });
      if (index >= 0) {
        let detailsIndex = this.listMonthlyItem[
          index
        ].inspection_details.findIndex((item) => {
          return (
            item.main_point_id === this.main_point_id &&
            item.inspection_item_id === this.inspection_item_id
          );
        });
        this.$delete(
          this.listMonthlyItem[index].inspection_details,
          detailsIndex
        );
        let params = {
          major_classification_id: this.majorClassificationId,
          middle_classification_id: this.middleClassificationId,
          test_item_id: this.testItemId,
          inspection_item_id: this.inspectionItemId,
          main_point_id: this.mainPointId,
        };
        this.tempData.monthInspection.details.delete.push(params);
      }
      this.onEditting();
      this.closeFormDialog();
    },
    async submitDeleteMonthlyItem(element) {
      if (
        !element.major_classification_id.toString().includes("Temp") &&
        !element.middle_classification_id.toString().includes("Temp") &&
        !element.test_item_id.toString().includes("Temp") &&
        !element.inspection_item_id.toString().includes("Temp") &&
        !element.main_point_id.toString().includes("Temp")
      ) {
        let params = {
          major_classification_id: element.major_classification_id,
          middle_classification_id: element.middle_classification_id,
          test_item_id: element.test_item_id,
          inspection_item_id: element.inspection_item_id,
          main_point_id: element.main_point_id,
        };
        await Store.dispatch("Machines/deleteMonthlyItem", params);
      }
    },
    openDialogAddData() {
      this.titleHeader = "大分類";
      this.popups.isShowFormDialog = true;
    },
    openFormDialogTabTwo() {
      this.titleHeader = "機械名";
      this.popups.isShowFormDialogTabTwo = true;
    },
    openMonthlyItemDialog(id) {
      this.testItemId = id;
      this.titleHeader = "点検項目追加";
      this.popups.isShowDialogAddMonthlyItem = true;
    },
    closeFormDialog() {
      this.popups.isShowFormDialog = false;
      this.popups.isShowFormDialogTabTwo = false;
      this.popups.isShowDialogEditMachineLevelOne = false;
      this.popups.isShowDialogEditMachineLevelTwo = false;
      this.popups.isShowDialogAddNew = false;
      this.popups.isShowDialogAddMonthlyItem = false;
      this.clearAddInputData();
    },
    resetAction() {
      this.actions.isReset = false;
      this.actions.isShowDelete = false;
      this.actions.isShowAddNew = false;
      this.actions.isShowTemplateOne = false;
      this.actions.isShowTemplateTwo = false;
    },
    activeTab(index, isCallImage = true) {
      this.resetActive();
      this.resetAction();
      switch (index) {
        case 1:
          this.actions.isShowDelete = true;
          this.actions.isReset = true;
          this.tab = 0;
          break;
        case 2:
          this.actions.isShowTemplateOne = true;
          this.actions.isShowDelete = true;
          this.disableActions.disableDelete = true;
          this.tab = 1;
          this.getListPrecaution();
          this.getListDailyItem();
          break;
        case 3:
          this.actions.isShowAddNew = true;
          this.actions.isShowTemplateTwo = true;
          this.tab = 2;
          this.getListMonthlyItem();
          break;
      }
      if (isCallImage && index === 1) {
        this.getImageUrl();
      }
    },
    resetActive() {
      this.tab = null;
    },
    openDialogTemplate(template) {
      if (template == 1) {
        this.popups.isShowDialogTemplateOne = true;
      }
      if (template == 2) {
        this.popups.isShowDialogTemplateTwo = true;
      }
    },
    cloneDialogTemplate() {
      this.popups.isShowDialogTemplateOne = false;
      this.popups.isShowDialogTemplateTwo = false;
      this.getListMonthlyItem();
    },
    openDialogEditMachine(level, id, key = 0) {
      this.idMachineEdit = id;
      switch (level) {
        case 1:
          this.titleHeader = "大分類";
          this.popups.isShowDialogEditMachineLevelOne = true;
          this.inputData.major_classification_edit = this.listMajor.filter(
            (x) => x.major_classification_id === id
          )[0]["major_classification"];
          this.itemActive.tabOne = key;
          break;
        case 2:
          this.titleHeader = "機械名";
          this.popups.isShowDialogEditMachineLevelTwo = true;
          this.inputData.middle_classification_edit = this.listMiddle.filter(
            (x) => x.middle_classification_id === id
          )[0]["middle_classification"];
          this.inputData.middle_classification_furigana_edit =
            this.listMiddle.filter((x) => x.middle_classification_id === id)[0][
              "middle_classification_furigana"
            ];
          this.itemActive.tabTwo = key;
          break;
      }
    },
    onDeleteTab() {
      if (this.tab == 1) {
        if (this.changeValueCheckBox.precautionAndDailyItem) {
          this.openDialogConfirm(
            this.confirmAction.deletePrecautionAndDailyItem
          );
        }
        if (this.changeValueCheckBox.precautions) {
          this.openDialogConfirm(this.confirmAction.deletePrecautions);
        }
        if (this.changeValueCheckBox.dailyItem) {
          this.openDialogConfirm(this.confirmAction.deleteDailyItemItem);
        }
      }
      if (this.tab == 0) {
        this.openDialogConfirm(this.confirmAction.deleteImage);
        this.popupConfirm.titleDialog = "画像の削除";
        this.popupConfirm.messageDialog =
          "以下の機械の画像を削除してもよろしいですか？";
        let middle_classification = this.listMiddle.filter(
          (x) => x.middle_classification_id === this.middleClassificationId
        )[0]["middle_classification"];
        this.popupConfirm.items = [middle_classification];
      }
    },
    openDialogAddNew() {
      this.titleHeader = "月例点検項目";
      this.popups.isShowDialogAddNew = true;
    },
    activeItem(item, level, id, isCallImage = true) {
      this.imageUrl = null;
      this.disableActions.disableReset = true;
      switch (level) {
        case 1:
          this.majorClassificationId = id;
          this.getListMiddle(id);
          this.activeTab(1, false);
          this.itemActive.tabOne = item;
          this.objectMapping.majorTitle =
            this.listMajor.filter((x) => x.major_classification_id === id)[0] ==
            undefined
              ? ""
              : this.listMajor.filter(
                  (x) => x.major_classification_id === id
                )[0]["major_classification"];
          this.activeItem(0, this.levelTab.Two, id, false);
          this.$refs.scrollMiddle.scrollTop = 0;
          break;
        case 2:
          this.activeTab(1, false);
          this.middleClassificationId = id;
          this.itemActive.tabTwo = item;
          this.objectMapping.middleTitle =
            this.listMiddle.filter(
              (x) => x.middle_classification_id === id
            )[0] == undefined
              ? ""
              : this.listMiddle.filter(
                  (x) => x.middle_classification_id === id
                )[0]["middle_classification"];
          break;
      }
      if (level === 2 && isCallImage) {
        this.getImageUrl();
      }
    },
    resetDeleteAction() {
      this.statusAction.isMajor = false;
      this.statusAction.deleteMajor = false;
      this.statusAction.isMiddle = false;
      this.statusAction.deleteInspectionDetails = false;
      this.statusAction.deletePrecautions = false;
      this.statusAction.deleteDailyItemItem = false;
      this.statusAction.deleteTestItem = false;
      this.statusAction.deleteImage = false;
      this.statusAction.isMiddle = false;
      this.statusAction.isMiddle = false;
      this.statusAction.isMajor = false;
    },
    resetMessageConfirm() {
      this.popupConfirm.titleDialog = "点検項目削除";
      this.popupConfirm.messageDialog = "点検項目を削除しますか？";
      this.popupConfirm.items = [];
    },
    openDialogConfirm(obj) {
      this.popupConfirm.items = [];
      if (obj === this.confirmAction.deletePrecautionAndDailyItem) {
        this.dailyItemIds.forEach((element) => {
          this.popupConfirm.items.push(
            this.listDailyItem.filter((x) => {
              return (
                x.inspection_item_id ===
                  parseInt(element["inspection_item_id"]) &&
                x.main_point_id == element["main_point_id"]
              );
            })[0]["inspection_item"]
          );
        });
        this.precautionsIds.forEach((element) => {
          this.popupConfirm.items.push(
            this.listPrecaution.filter((x) => x.precautions_id == element)[0][
              "precautions"
            ]
          );
        });
        this.statusAction.deletePrecautionAndDailyItem = true;
      }
      if (obj === this.confirmAction.deleteImage) {
        this.statusAction.deleteImage = true;
      }
      if (obj === this.confirmAction.deleteTestItem) {
        this.popupConfirm.titleDialog = "月例点検項目削除";
        this.popupConfirm.messageDialog = "月例点検項目を削除しますか？";
        this.popupConfirm.items.push(
          this.listMonthlyItem.filter(
            (x) => x.test_item_id === this.testItemId
          )[0]["test_item"]
        );
        this.statusAction.deleteTestItem = true;
      }
      if (obj === this.confirmAction.deleteMajor) {
        this.popupConfirm.titleDialog = "大分類削除";
        this.popupConfirm.messageDialog = "大分類を削除しますか？";
        this.popupConfirm.items.push(
          this.listMajor.filter(
            (x) => x.major_classification_id === this.majorClassificationId
          )[0]["major_classification"]
        );
        this.statusAction.deleteMajor = true;
      }
      if (obj === this.confirmAction.deleteDailyItemItem) {
        this.dailyItemIds.forEach((element) => {
          this.popupConfirm.items.push(
            this.listDailyItem.filter((x) => {
              return (
                x.inspection_item_id == element["inspection_item_id"] &&
                x.main_point_id == element["main_point_id"]
              );
            })[0]["inspection_item"]
          );
        });
        this.statusAction.deleteDailyItemItem = true;
      }
      if (obj === this.confirmAction.deletePrecautions) {
        this.precautionsIds.forEach((element) => {
          this.popupConfirm.items.push(
            this.listPrecaution.filter((x) => x.precautions_id == element)[0][
              "precautions"
            ]
          );
        });
        this.statusAction.deletePrecautions = true;
      }
      if (obj === this.confirmAction.middle) {
        this.popupConfirm.titleDialog = "機械名削除";
        this.popupConfirm.messageDialog = "機械名を削除しますか？";
        this.popupConfirm.items.push(
          this.listMiddle.filter(
            (x) => x.middle_classification_id === this.middleClassificationId
          )[0]["middle_classification"]
        );
        this.statusAction.isMiddle = true;
      }
      if (obj === this.confirmAction.deleteInspectionDetails) {
        this.popupConfirm.titleDialog = "点検項目削除";
        this.popupConfirm.messageDialog = "点検項目を削除しますか？";
        let array = this.listMonthlyItem.filter(
          (x) => x.test_item_id === this.testItemId
        )["0"]["inspection_details"];
        this.popupConfirm.items.push(
          array.filter((x) => {
            return (
              x.inspection_item_id === this.inspectionItemId &&
              x.main_point_id === this.mainPointId
            );
          })[0]["inspection_item"]
        );
        this.statusAction.deleteInspectionDetails = true;
      }
      this.closeFormDialog();
      this.popups.isShowDialogConfirm = true;
    },
    closeDialogConfirm() {
      this.popups.isShowDialogConfirm = false;
      this.resetDeleteAction();
      this.resetMessageConfirm();
    },
    deleteInspectionDetails(data) {
      this.inspectionItemId = data.inspection_item_id;
      this.mainPointId = data.main_point_id;
      this.testItemId = data.test_item_id;
      this.openDialogConfirm(this.confirmAction.deleteInspectionDetails);
    },
    deleteTestItemDetail(data) {
      this.testItemId = data.test_item_id;
      this.openDialogConfirm(this.confirmAction.deleteTestItem);
    },
    actionDialogConfirm() {
      if (this.statusAction.deletePrecautionAndDailyItem) {
        this.deleteDailyItem();
        this.deletePrecaution();
      }
      if (this.statusAction.deleteImage) {
        this.deleteImage();
      }
      if (this.statusAction.deleteMajor) {
        this.deleteMajor();
      }
      if (this.statusAction.deleteDailyItemItem) {
        this.deleteDailyItem();
      }
      if (this.statusAction.deletePrecautions) {
        this.deletePrecaution();
      }
      if (this.statusAction.deleteTestItem) {
        this.deleteTestItem();
      }
      if (this.statusAction.deleteInspectionDetails) {
        let array = this.listMonthlyItem.filter(
          (x) => x.test_item_id === this.testItemId
        )["0"]["inspection_details"];
        this.listMonthlyItem.filter((x) => x.test_item_id === this.testItemId)[
          "0"
        ]["inspection_details"] = array.filter((x) => {
          return (
            x.inspection_item_id !== this.inspectionItemId &&
            x.main_point_id !== this.mainPointId
          );
        });
        this.deleteMonthlyItem();
      }
      if (this.statusAction.isMiddle) {
        this.deleteMiddle();
      }
      this.resetDeleteAction();
      this.resetMessageConfirm();
      this.popups.isShowDialogConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-window {
  padding: 0 !important;
}
.main-header {
  position: sticky;
  top: 0;
  background-color: $background_color;
  align-items: center;
  height: 48px;
  z-index: 2;
}
.tab-header {
  height: fit-content;
  font-size: 16px;
  align-items: center;
}
.wrap-content {
  padding: 0 10px 10px 10px;
}
.list-item {
  padding: 0 10px;
  height: 50px;
  font-size: 16px;
  align-items: center;
  background-color: $primary_color;
}
.border-bottom {
  border-bottom: 1px solid $border_color;
}
.border-left {
  border-left: 1px solid $border_color;
}
.border-right {
  border-right: 1px solid $border_color;
}
.active-item {
  background-color: $tint_color;
}
.scrollbar {
  overflow-y: scroll;
}
.caution {
  color: $warning_color;
  font-size: 16px;
  font-weight: bold;
}
</style>
