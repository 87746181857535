// src/plugins/vuetify.js
import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const COLORS = {
  primary: "#32c6b4",
  primary_sub: "#083874",
  success: "#32c6b4",
  warning: "#ff7b52",
  form_bg: "#f8f8f8",
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: COLORS.primary,
        secondary: COLORS.primary_sub,
        accent: colors.indigo.base,
        warning: COLORS.warning,
        form_bg: COLORS.form_bg,
        success: COLORS.success,
      },
      options: {
        // 色変数を使えるように
        // ex. var(--v-primary-base);
        customProperties: true,
      },
    },
  },
});
