<template>
  <div :style="style">
    <div class="label">
      {{ label }}
      <div v-if="required && editable" class="required">必須</div>
    </div>
    <div><slot></slot></div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
  },
  computed: {
    style() {
      return `width:${this.width}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.label {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: $accent_color;
  min-height: 35px !important;
}
.required {
  font-size: 18px;
  font-weight: bold;
  color: $primary_color;
  background-color: $required_color;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  transform: scale(0.7);
}
</style>
