<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <div class="d-flex bg">
        <div class="flex-column mr-5">
          <div class="flex-grow-1">
            <AddMember
              :mainHeight="params.mainHeight"
              :add="add"
              @loading="loading"
              @formUpdate="formUpdate"
            />
          </div>
          <div class="flex-grow-1">
            <RejectedMenber :reject="reject" @formUpdate="formUpdate" />
          </div>
        </div>
        <div class="ml-5">
          <SiteUserManager
            :mainHeight="params.mainHeight"
            :add="add"
            @loading="loading"
            @formUpdate="formUpdate"
          />
        </div>
      </div>
      <Popup width="500px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          @close="popups.isShowConfirmDialog = false"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
      <Loader :isLoading="popups.isLoading"></Loader>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-5"
          outlined
          color="primary"
          @click="popups.isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          class="ml-5"
          color="primary"
          :key="keySubmit"
          @click.once="onSubmit"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn></v-toolbar
      >
    </template>
  </FormDialogLayout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import { TITLE, FORMS } from "@/constants/FIELD_USER";
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";

// Components
import Popup from "@/components/common/Popup";
import Pagination from "@/components/forms/elements/Pagination";
import ValidationCallback from "@/components/forms/elements/ValidationCallback";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import Loader from "@/components/forms/elements/Loader";
import SiteUserManager from "@/components/forms/fields/fieldUsers/SiteUserManager";
import AddMember from "@/components/forms/fields/fieldUsers/AddMember";
import RejectedMenber from "@/components/forms/fields/fieldUsers/RejectedMenber";

//ストア
const STORE = "FieldUser";

export default {
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    Pagination,
    FormDialogLayout,
    ConfirmDialog,
    Loader,
    SiteUserManager,
    AddMember,
    RejectedMenber,
  },
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      TITLE,
      FORMS,
      tab: null,
      isClickCloseBtn: false,
      isChanged: false,
      keySubmit: false,
      popups: {
        isShowConfirmDialog: false,
        isLoading: false,
      },
      add: [],
      reject: [],
    };
  },
  mounted() {},
  computed: {
    formtitle() {
      return this.TITLE.NEW;
    },
    CURRENT_SITE() {
      return Store.state.GlobalHeader.selectedSite;
    },
  },
  methods: {
    formUpdate(type, item) {
      switch (type) {
        // 解除
        case 0:
          if (item.isNew) {
            let index = this.add.findIndex((el) => {
              return el.id === item.id;
            });
            if (index >= 0) {
              this.$delete(this.add, index);
            }
            return;
          }
          this.reject.push(item);
          break;
        // 追加
        case 1:
          this.add.push(item);
          break;
        case 2:
          this.add.push(item);
          break;
      }
      this.isChanged = true;
    },
    loading(isLoading) {
      this.popups.isLoading = isLoading;
    },
    async onSubmit() {
      const field_user_ids = this.reject.map((items) => items.id);
      const user_ids = this.add.map((items) => items.id);
      const apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        user_ids,
        field_user_ids,
      };
      const result = await Store.dispatch(`${STORE}/update`, apiParams);
      this.keySubmit = !this.keySubmit;
      if (!result.hasError) {
        this.$emit("cancel");
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.$emit("updateChecked", user_ids);
      }
    },
    closeForm() {
      this.$emit("cancel");
    },
    onClickBtnClose() {
      if (this.isChanged) {
        this.popups.isClickCloseBtn = true;
        this.popups.isShowConfirmDialog = true;
      } else {
        this.$emit("cancel");
      }
    },
  },
};
</script>
