<template>
  <div>
    <v-app-bar class="portalHeader" dense>
      <v-btn icon @click="openDrawer">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
      <div class="sites_name_wrapper">
        <v-btn @click="openSearchDialog">
          <div class="sites_name">
            {{ CurrentSite ? CurrentSite.name : "-" }}
          </div>
          <v-spacer></v-spacer>
          <v-icon> mdi-menu-down </v-icon>
        </v-btn>
      </div>
      <div class="portalHeader-title">
        <img
          class="portalHeader-logo"
          src="@/assets/rakuraku.png"
          @click="onClickLogo"
        />
        <div class="portalHeader-label">（現場管理）</div>
      </div>
      <v-tabs right v-model="active_tab">
        <v-tab
          v-for="val in HEADER_MENU_ITEMS_FIELD_ARRAY"
          :key="val.id"
          @change="(menu = val), onChange(val.id)"
          >{{ val.name }}</v-tab
        >
      </v-tabs>
    </v-app-bar>
    <Popup width="500px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場管理画面"
        :gotoMaster="true"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import {
  HEADER_MENU_ITEMS_FIELD_ARRAY,
  HEADER_MENU_ITEMS_INHOUSE,
} from "@/constants/GLOBALHEADER";
import { COMPANY_TYPE } from "@/constants/COMMON";
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";

export default {
  components: {
    FieldsSelectDialog,
    Popup,
  },
  data() {
    return {
      // タブメニュー表示項目
      HEADER_MENU_ITEMS_FIELD_ARRAY,
      // 選択中のタブメニューid
      active_tab: 0,
      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },
      // 検索ダイアログ表示
      isShowSearchDialog: false,
      // 現場id
      siteId: null,
    };
  },
  async mounted() {
    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getInHouseMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );
    /**
     * 現場一覧を取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = [...data];
        this.formValues.items = this.getSiteByRole(siteList);
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.state.Login.user?.company_type;
    },
  },
  methods: {
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      const selectedMenu = this.HEADER_MENU_ITEMS_FIELD_ARRAY.find((item) => {
        return item.id === menuId;
      });
      this.$router.push(selectedMenu.path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    // ドロワー
    openDrawer() {
      Store.dispatch("Drawer/show");
    },
    // 検索ダイアログ
    async openSearchDialog() {
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
      this.isShowSearchDialog = true;
    },
    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },
    // フォームの変更を受け取る
    formUpdate(values) {
      this.formValues = { ...values };
      /**
       * 現場選択
       */
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });
        // ダイアログclose
        this.isShowSearchDialog = false;
        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;
      }
    },
    /**
     * on click logo
     */
    onClickLogo() {
      this.$router
        .push(HEADER_MENU_ITEMS_INHOUSE.MACHINES.path)
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
    },
    /**
     * filter sites by role company
     */
    getSiteByRole(siteList) {
      if (this.companyType === COMPANY_TYPE.PATNER) {
        return siteList.filter(
          (e) => e?.asigned_field_type === COMPANY_TYPE.PATNER
        );
      }
      return siteList;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-toolbar__content {
  background-color: $main_header_bg_color !important;
}
.v-tab {
  color: $secondary_color !important;
}
.v-tabs ::v-deep .v-tab--active {
  border: none !important;
  border-radius: 0 !important;
}
.v-tabs ::v-deep .v-tabs-slider-wrapper {
  color: $secondary_color !important;
}
::v-deep .v-tabs-bar__content {
  background-color: $main_header_bg_color;
}
.v-app-bar__nav-icon ::v-deep .v-icon {
  color: $primary_color !important;
}
</style>
