// label for RK2-1265
const commonLabel = [
  {
    text: "点検実施会社",
    value: "company_name",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "点検実施者",
    value: "inspector_name",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "点検実施日",
    value: "inspection_at",
    align: "center",
    sortable: false,
    width: "40%",
  },
  {
    text: "状態",
    value: "result",
    align: "left",
    sortable: false,
    width: "20%",
  },
];
// label table form 1
export const INSPECTION_FORM1_LABELS = [
  {
    text: "ステータス",
    value: "approved_status",
    align: "right",
    sortable: false,
    width: "10%",
  },
  ...commonLabel,
];
export const INSPECTION_FORM1_LABELS_OTHER_COMPANY = [
  {
    text: "協力会社承認状況",
    value: "approved_status",
    align: "right",
    sortable: false,
    class: "header-custom",
  },
  ...commonLabel,
];

export const INSPECTION_GUIDANCE_LABELS = [
  {
    text: "会社名",
    value: "company_name",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "点検機械",
    value: "count_machine",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "指導",
    value: "guidance_status",
    align: "center",
    sortable: false,
    width: "20%",
  },
  {
    text: "指導日",
    value: "lead_approved_at",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "",
    value: "preview_guild_dance",
    align: "right",
    sortable: false,
    width: "20%",
  },
];

export const REMARKS_LABELS = [
  {
    text: "点検内容",
    value: "remarks",
    align: "left",
  },
];

// label table form 2
export const INSPECTION_FORM2_LABELS = [
  {
    text: "点検",
    value: "status",
    align: "left",
    sortable: false,
    width: "10%",
  },
  {
    text: "点検事項",
    value: "inspection_items",
    align: "left",
    sortable: false,
    width: "30%",
  },
  {
    text: "主眼点",
    value: "main_point",
    align: "left",
    sortable: false,
    width: "60%",
  },
];

// label table list inspections result
export const INSPECTIONS_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "15%",
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "18%",
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    width: "17%",
  },
  {
    text: "点検会社",
    value: "company_inspector",
    align: "left",
    sortable: false,
    width: "17%",
  },
  {
    text: "未承認（全件）",
    value: "unapproved_count",
    align: "left",
    width: "10%",
  },
  {
    text: "最終点検日時",
    value: "inspection_at",
    align: "left",
    width: "12%",
  },
  {
    text: "",
    value: "flag_error",
    align: "left",
    width: "10%",
  },
];

export const CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "18%",
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "18%",
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    width: "18%",
  },
  {
    text: "未承認(全件)",
    value: "unapproved_count",
    align: "left",
    width: "15%",
  },
  {
    text: "最終点検日時",
    value: "inspection_at",
    align: "left",
    width: "15%",
  },
  {
    text: "",
    value: "flag_error",
    align: "left",
    width: "10%",
  },
];

export const CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2 = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "15%",
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "18%",
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    width: "17%",
  },
  {
    text: "点検会社",
    value: "company_inspector",
    align: "left",
    sortable: false,
    width: "17%",
  },
  {
    text: "未指導(全件)",
    value: "unapproved_count",
    align: "left",
    width: "9%",
  },
  {
    text: "最終点検日時",
    value: "inspection_at",
    align: "left",
    width: "11%",
  },
  {
    text: "",
    value: "flag_error",
    align: "left",
    width: "12%",
  },
];

// export const INSPECTION_RESULT_TABLE_TAB_ITEMS = {
//   TAB_DATE: { id: 0, name: "作業前点検" },
//   TAB_MONTH: { id: 1, name: "月例点検" },
//   TAB_GUIDANCE: { id: 2, name: "点検指導実績一覧" },
// };

export const ATTRIBUTE_DIALOG_ARRRAY = {
  CANCEL_APPROVAL: {
    id: 1,
    name: "承認解除",
    message: "点検結果を承認解除します。",
  },
  APPROVAL: { id: 2, name: "承認", message: "点検結果を承認します。" },
  APPROVAL_ALL: { id: 3, name: "承認", message: "点検結果を承認します。" },
  GUIDANCE_ALL: { id: 4, name: "指導", message: "確認/指導します。" },
};

export const INSPECTION_RESULT_CATEGORY = {
  REJECT_APPROVE: "reject_approve",
  APPROVE_ITEMS: "approve_items",
  APPROVE_ALL_ITEMS: "approve_all_items",
  FIELD_DATE_FROM: "inspection_date_from",
  FIELD_DATE_TO: "inspection_date_to",
  MAJOR_ID: "major_classification_id",
  MEDIUM_ID: "middle_classification_id",
  GUIDANCE_ALL: "guidance",
};

// INSPECTION INITAL ITEM
export const INSPECTION_INITAL_ITEM = {
  ids: [],
  type: null,
  major_item_id: null,
  major_item: null,
  medium_item_id: null,
  medium_item: null,
  machine_id: null,
  machine_name: null,
  company_id: null,
  company_name: null,
  acceptant_number: null,
  approved_status: 0,
  inspection_count: 0,
  repair_count: 0,
  malfunction_count: 0,
};

export const RESULT_STATUS = {
  USE_AFTER_REPAIR: 1,
  NOT_USE_DUE_TO_FAULT: 0,
  GOOD: 2,
};

export const INSPECTION_RESULT_TAB = ["点検結果", "写真", "是正報告"];

export const CHECK_STATUS_MESSAGE = {
  INSPECTION1: "レ　良好　　〇　修理して使用する　　×　故障のため使用しない",
  INSPECTION2: "レ　良好　　〇　調整または補修したとき　　×　調整または要修理",
};

export const FLAG_RELATE_FIELD = {
  NOT_RELATE_FIELD: 0,
  IS_RELATE_FIELD: 1,
};

export const FLAG_MACHINE_FIELD_MANAGER = {
  NOT_FLAG: 0,
  IS_FLAG: 1,
};

export const INFO_DELETE_CONFIRM_DIALOG = {
  INFO_DELETE_ERROR: {
    title: "点検実績削除",
    message: "選択したデータは全て承認済みのため、削除できません。",
  },
  INFO_CONFIRM_DELETE: {
    title: "点検実績削除",
    message: "選択した未承認の点検実績を削除します。",
  },
};

export const APPROVE_STATUS = {
  UNAPPROVE: { id: 0, name: "未承認" },
  APPROVE: { id: 1, name: "承認済" },
};
