<template>
  <v-menu
    v-model="isShow"
    ref="menu"
    transition="scroll-y-transition"
    :nudge-top="4"
    :nudge-left="60"
    offset-y
    min-width="auto"
    dense
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <SearchFormWrapper>
        <InputText
          name="pickDate"
          placeholder="日付を選択"
          :values="computedDateFormatted"
          :readonly="true"
          :editable="true"
          innerIcon="mdi-calendar-today"
          @click="isShow = !isShow"
        />
      </SearchFormWrapper>
    </template>
    <div>
      <v-date-picker
        v-model="val"
        :color="COLORS.CALENDAR_COLOR"
        locale="ja"
        type="month"
        :day-format="(val) => new Date(val).getDate()"
        :first-day-of-week="0"
        no-title
        scrollable
        dense
        @change="onInput(val)"
      >
      </v-date-picker>
      <div>
        <v-btn class="btn left-btn" @click="onInput(new Date())">クリア</v-btn>
        <v-btn class="btn right-btn" @click="isShow = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </div>
  </v-menu>
</template>
<script>
import { format, parseISO } from "date-fns";
import { COLORS } from "@/constants/COMMON";

// componets
import InputText from "@/components/forms/elements/InputText";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper.vue";

export default {
  data: () => {
    return {
      COLORS,
      val: "",
      dateString: {
        pickDate: "",
      },
      isShow: false,
    };
  },
  components: {
    InputText,
    SearchFormWrapper,
  },
  props: {
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name && formValues[name]) {
          const _date = new Date(formValues[name]);
          this.val = format(_date, "yyyy-MM");
        }
      },
      { immediate: true, deep: true }
    );
  },
  computed: {
    computedDateFormatted() {
      this.dateString.pickDate = this.val
        ? format(parseISO(this.val), "yyyy/MM")
        : "";
      return this.dateString;
    },
  },
  methods: {
    onInput(val) {
      const _val = format(new Date(val), "yyyy/MM");
      this.$emit("onInput", { name: this.name, value: _val });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-text-field__slot input {
  width: 100px !important;
  font-size: 16px !important;
  &:hover {
    cursor: pointer;
  }
}
.btn {
  position: absolute;
  top: 0;
  background-color: transparent !important;
  height: 40px !important;
  min-width: 60px !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  color: $page_title_color;
}
.left-btn {
  left: 0;
}
.right-btn {
  right: 0;
}
.v-picker ::v-deep .v-btn {
  font-size: 12px !important;
  font-weight: normal !important;
}
::v-deep .v-date-picker-table {
  padding: 0 !important;
  height: fit-content !important;
}
::v-deep .v-date-picker-header {
  left: 65px !important;
  width: 160px !important;
  height: 40px !important;
  padding: 0 !important;
  .v-date-picker-header__value {
    font-size: 16px !important;
    width: 30px !important;
  }
  .v-btn {
    width: fit-content !important;
  }
}
::v-deep .v-text-field__details {
  display: none !important;
}
</style>
