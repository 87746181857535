<template>
  <v-form autocomplete="off">
    <v-list-item>
      <v-list-item-content>
        <div class="d-flex justify-space-between">
          <Label
            class="flex-grow-1 mr-5"
            label="姓"
            :editable="editable"
            required
          >
            <InputText
              name="name_sei"
              :autofocus="autofocus"
              :values="formValues"
              :editable="editable"
              placeholder="山田"
              validation_label="姓"
              validation_rules="required|max:64"
              @onInput="onInput"
            />
          </Label>
          <Label
            class="flex-grow-1 ml-5"
            label="名"
            :editable="editable"
            required
          >
            <InputText
              name="name_mei"
              :values="formValues"
              :editable="editable"
              placeholder="太郎"
              validation_label="名"
              validation_rules="required|max:64"
              @onInput="onInput"
            />
          </Label>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <div class="d-flex justify-space-between">
          <Label
            class="flex-grow-1 mr-5"
            label="セイ"
            :editable="editable"
            required
          >
            <InputText
              name="kana_sei"
              :values="formValues"
              :editable="editable"
              placeholder="ヤマダ"
              validation_label="セイ"
              validation_rules="required|max:128|kana"
              @onInput="onInput"
            />
          </Label>
          <Label
            class="flex-grow-1 ml-5"
            label="メイ"
            :editable="editable"
            required
          >
            <InputText
              name="kana_mei"
              :values="formValues"
              :editable="editable"
              placeholder="タロウ"
              validation_label="メイ"
              validation_rules="required|max:128|kana"
              @onInput="onInput"
            />
          </Label>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <Label label="所属会社" :editable="editable" required>
          <SelectWithFilter
            pinId="selectUserFormPage1"
            name="company_id"
            :values="formValues"
            :items="COMPANYS"
            :editable="editable && !disableSelectCompany"
            validation_label="所属会社"
            validation_rules="required"
            :isChangeMessage="true"
            noDataText="会社の登録がありません"
            @onInput="onInput"
            @handleAction="isShowRegisterCompany = true"
          ></SelectWithFilter>
        </Label>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <Label label="管理者フラグ" :editable="editable">
        <SwitchInput
          name="role"
          label="管理者"
          :values="formValues"
          :editable="editable && !isAdminFlag"
          @onInput="onInput"
        />
        <span class="caution">
          ※「点検者」アカウントは各ユーザーで登録してください。
        </span>
      </Label>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <Label label="メールアドレス" :editable="editable" required>
          <InputText
            name="email"
            :values="formValues"
            :editable="editable && !isAdminFlag"
            placeholder="example@example.com"
            validation_label="メールアドレス"
            validation_rules="max:255|alphanumericHalfSize|email|required"
            @onInput="onInput"
          />
        </Label>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="isShowQA">
      <v-list-item-content>
        <Label label="合言葉 ※パスワード再設定時に使用します"></Label>
        <div class="d-flex justify-space-between">
          <Label
            class="flex-grow-1 mr-5"
            label="質問"
            :editable="editable"
            required
          >
            <InputText
              name="question"
              :values="formValues"
              validation_label="質問"
              :editable="editable"
              validation_rules="required|max:255"
              placeholder="らくらく"
              @onInput="onInput"
            />
          </Label>
          <Label
            class="flex-grow-1 ml-5"
            label="回答"
            :editable="editable"
            required
          >
            <InputText
              name="answer"
              :values="formValues"
              :editable="editable"
              validation_label="回答"
              validation_rules="required|max:255"
              placeholder="点検"
              @onInput="onInput"
            />
          </Label>
        </div>
      </v-list-item-content>
    </v-list-item>
    <Popup :dialog="isShowRegisterCompany">
      <CompanyForm
        :isNewItem="true"
        @cancel="isShowRegisterCompany = false"
        @updateCompany="getListCompanies"
      />
    </Popup>
  </v-form>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import SelectWithFilter from "../elements/SelectWithFilter.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import CompanyForm from "@/components/forms/company/index";
import Popup from "@/components/common/Popup.vue";
import { Store } from "@/store/Store.js";
import { COMPANY_TYPE } from "@/constants/COMMON";

export default {
  data: () => {
    return {
      isShowRegisterCompany: false,
      isCompanyChanged: false,
      formValues: {},
      COMPANYS: [],
      autofocus: false,
    };
  },
  components: {
    Label,
    InputText,
    SelectWithFilter,
    SwitchInput,
    CompanyForm,
    Popup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.getListCompanies();
    this.$watch(
      () => this.item,
      (newValue) => {
        let formValues = { ...newValue };
        if (
          this.isNewItem &&
          this.USER_LOGIN.company_type !== COMPANY_TYPE.ADMIN &&
          !this.isCompanyChanged
        ) {
          formValues.company_id = this.USER_LOGIN.company_id;
        }
        this.formValues = formValues;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        if (newValue && !this.isNewItem) {
          const textFieldFirst = document.getElementsByTagName("input");
          textFieldFirst[9].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Company/getData`],
      (data) => {
        this.COMPANYS = [...data];
        this.COMPANYS.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    USER_LOGIN() {
      return Store.state.Login.user;
    },
    disableSelectCompany() {
      let companyType = this.USER_LOGIN?.company_type;
      switch (companyType) {
        case COMPANY_TYPE.PATNER:
        case COMPANY_TYPE.RENTAL:
          return true;
        case COMPANY_TYPE.OWNER:
        case COMPANY_TYPE.ADMIN:
          return false;
        default:
          return false;
      }
    },
    isAdminFlag() {
      return this.formValues["admin_user_flg"] === 1;
    },
    isShowQA() {
      return (
        this.isNewItem ||
        this.USER_LOGIN.company_type === 9 ||
        this.formValues.id == this.USER_LOGIN.id
      );
    },
  },
  methods: {
    async getListCompanies(params) {
      await Store.dispatch("Company/get");
      if (params) {
        this.isCompanyChanged = true;
        this.formValues["company_id"] = params;
      }
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      if (name == "company_id") {
        this.isCompanyChanged = true;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.caution {
  color: $warning_color;
  font-size: 14px;
}
</style>
