<template>
  <v-card class="mx-auto my-12 mt-0 mb-0 mb-0 pb-8">
    <div class="d-flex justify-space-between dialog-header">
      <Label class="dialog-title" label="パスワード再設定"></Label>
      <v-btn icon @click="$emit('close')">
        <v-icon class="icon-close">mdi-close</v-icon>
      </v-btn>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-list-item>
          <v-list-item-content>
            <Label label="現在のパスワード">
              <Password
                placeholder="現在のパスワードを入力"
                class="custom-input"
                name="password"
                :values="formValue"
                :editable="true"
                validation_label="現在のパスワード"
                validation_rules="required|new-password"
                @onInput="onInput"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="新しいパスワード">
              <Password
                placeholder="新しいパスワードを入力"
                class="custom-input"
                name="password_new"
                :values="formValue"
                :editable="true"
                validation_label="新しいパスワード"
                validation_rules="required|new-password"
                @onInput="onInput"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="新しいパスワード（確認）">
              <Password
                placeholder="新しいパスワード（確認）を入力"
                class="custom-input"
                name="password_new_re"
                :values="formValue"
                :editable="true"
                validation_label="新しいパスワード（確認）"
                :validation_rules="`required|new-password|passwordDiff:${formValue.password_new}`"
                @onInput="onInput"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              class="btn-confirm"
              height="48px"
              color="primary"
              :disabled="invalid"
              @click="UpdateAndBackToLogin"
              >送信</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import InputText from "@/components/forms/elements/InputText.vue";
import Label from "@/components/forms/elements/Label";
import Password from "@/components/forms/elements/Password.vue";
export default {
  components: {
    InputText,
    ValidationObserver,
    Label,
    Password,
  },
  data() {
    return {
      passwordShow: false,
      formValue: {
        password: "",
        password_new: "",
        password_new_re: "",
      },
    };
  },
  methods: {
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },
    UpdateAndBackToLogin() {
      this.$emit("set_new_pass", this.formValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-header {
  padding: 10px;
  ::v-deep .label {
    font-size: 20px !important;
  }
}

.v-form {
  padding: 0 8px 0 8px;
}
.custom-input {
  ::v-deep .v-input__control {
    margin-bottom: -15px;
    .v-input__slot {
      border-radius: 0px;
      height: 45px;
      width: 312px;
      display: -webkit-inline-box;
      background-color: rgb(255, 255, 255) !important;
      ::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
      .v-text-field__slot {
        width: 234px;
      }
      .v-input__append-inner {
        position: absolute;
        left: 244px;
        width: 39px;
      }
    }
  }
}
.btn-confirm {
  margin-top: 15px;
}
</style>
