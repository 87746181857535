<template>
  <v-card class="dialog">
    <div class="header">
      <v-card-title>
        <div class="title">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
    >
      <v-list two-line>
        <template>
          <div v-for="item in formValues.items" :key="item.field_id">
            <v-list-item ripple>
              <v-list-item-content
                @click="
                  onClick(
                    item.middle_classification_id,
                    item.major_classification_id
                  )
                "
              >
                <v-list-item-title class="machine_name">{{
                  item.middle_classification
                }}</v-list-item-title>
                <v-list-item-subtitle class="machine_group">{{
                  item.major_classification
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>
      <div v-if="formValues.items.length == 0" style="text-align: center">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  data() {
    return {
      NO_DATA_MESSAGE,
    };
  },
  components: {
    SearchSelectList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default() {
        return {
          keyword: "",
          items: [],
          selected: null,
          major_id: null,
        };
      },
    },
    onSearch: Function,
    close: Function,
  },

  methods: {
    search() {
      this.$emit("onSearch");
    },

    formUpdate(values) {
      this.$emit("formMachineUpdate", values);
    },

    onClick(middle_id, major_id) {
      const values = { ...this.formValues };
      values.selected = middle_id;
      values.major_id = major_id;
      this.$emit("formMachineUpdate", values);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.dialog {
  .header {
    position: sticky;
    top: 0;
    background-color: $background_color;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
.machine_name {
  font-size: 18px !important;
  font-weight: bold !important;
}
.machine_group {
  font-size: 12px !important;
}
</style>
