<template>
  <v-card class="mx-auto my-0 mb-0 pb-8">
    <ValidationObserver v-slot="{ invalid }">
      <div class="dialog-header sticky">
        <div class="dialog-title text-left">点検項目追加</div>
      </div>
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-list-item>
          <v-list-item-content>
            <p class="ml-2 mb-5">機械特有の点検項目を追加します。</p>
            <v-spacer></v-spacer>
            <Label label="点検事項" required editable>
              <InputText
                name="inspection_items"
                :values="formValue"
                :editable="true"
                validation_label="点検事項"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <Label label="主眼点" required editable>
              <InputTextArea
                name="main_point"
                :values="formValue"
                :editable="true"
                validation_label="主眼点"
                validation_rules="required"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <div style="text-align: right" class="mr-4">
          <v-btn outlined color="primary" class="mx-1 px-4" @click="onCancel">
            {{ DIALOG_BUTTON_NAMES.CANCEL }}
          </v-btn>
          <v-btn
            :disabled="invalid"
            color="primary"
            class="mx-1 px-5"
            @click="onSubmit"
          >
            {{ DIALOG_BUTTON_NAMES.ADD }}
          </v-btn>
        </div>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import Label from "@/components/forms/elements/Label.vue";
import Popup from "@/components/common/Popup.vue";
import { DIALOG_BUTTON_NAMES } from "@/constants/COMMON";

/**
 * 元請けからの招待フォーム
 * 1次請けを招待するフォーム
 */
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    InputText,
    InputTextArea,
    Label,
    Popup,
  },
  props: {
    chartId: {
      type: Number,
    },
  },
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      formValue: {
        inspection_items: "",
        main_point: "",
      },
    };
  },
  methods: {
    /**
     * フォームの値を更新
     */
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
    },

    /**
     * フォームを閉じる
     */
    onCancel() {
      this.$emit("onCancel");
    },

    /**
     * フォームを送信
     */
    onSubmit() {
      this.$emit("onSubmit", {
        inspection_items: this.formValue.inspection_items,
        main_point: this.formValue.main_point,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
</style>
