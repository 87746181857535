<template>
  <div class="tab-three-content">
    <div
      class="list-drop-drag"
      v-for="(item, key) in listMonthlyItem"
      :key="key"
    >
      <div class="d-flex">
        <div class="drop-zone">
          <div class="drag-el justify-space-between d-flex">
            <textarea
              ref="textarea"
              v-model="item.test_item"
              class="txt-area"
              @change="$emit('editMonthlyItem', listMonthlyItem, item)"
            ></textarea>
            <v-icon @click="deleteTestItem(item.test_item_id)">
              mdi-delete-outline
            </v-icon>
          </div>
        </div>
        <draggable
          class="drop-zone-lv2"
          v-model="item.inspection_details"
          @change="$emit('editMonthlyItem', listMonthlyItem, item)"
        >
          <div
            class="item-zone"
            v-for="value in item.inspection_details"
            :key="value.inspection_item_id"
          >
            <div class="drag-el">
              <div class="bg-el">
                <textarea
                  ref="textarea"
                  v-model="value.inspection_item"
                  class="txt-area"
                  @change="$emit('editMonthlyItem', listMonthlyItem, item)"
                ></textarea>
              </div>
            </div>
            <div class="drag-el">
              <div class="d-flex justify-space-between bg-el">
                <textarea
                  ref="textarea"
                  v-model="value.main_point"
                  class="txt-area"
                  @change="$emit('editMonthlyItem', listMonthlyItem, item)"
                ></textarea>
                <v-icon
                  @click="
                    deleteInspectionDetails(
                      value.inspection_item_id,
                      value.main_point_id,
                      item.test_item_id
                    )
                  "
                >
                  mdi-delete-outline
                </v-icon>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="text-right">
        <v-btn icon @click="pushData(item.test_item_id)">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "TabThreeContent",
  components: { draggable },
  props: {
    listMonthlyItem: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    this.$watch(
      () => this.listMonthlyItem,
      (data) => {
        if (data.length > 0) {
          this.resizeTextareas();
        }
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    window.addEventListener("resize", () => {
      if (this.listMonthlyItem.length > 0) {
        this.resizeTextareas();
      }
    });
  },
  methods: {
    resizeTextareas() {
      const textareas = this.$refs.textarea;
      if (Array.isArray(textareas)) {
        textareas.forEach((el) => {
          el.style.height = "auto";
          el.style.height = `${el.scrollHeight}px`;
        });
      } else if (textareas) {
        textareas.style.height = "auto";
        textareas.style.height = `${textareas.scrollHeight}px`;
      }
    },
    pushData(id) {
      this.$emit("plusData", id);
    },
    deleteInspectionDetails(inspection_item_id, main_point_id, test_item_id) {
      this.$emit("deleteInspectionDetails", {
        inspection_item_id,
        main_point_id,
        test_item_id,
      });
    },
    deleteTestItem(test_item_id) {
      this.$emit("deleteTestItem", {
        test_item_id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.tab-three-content {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.list-drop-drag {
  border-left: solid 10px $secondary_color;
  margin-top: 10px;
}
.drop-zone {
  width: 33%;
  min-height: 20px;
}
.drop-zone .drag-el {
  padding: 5px;
  background: rgba(0, 0, 0, 0.04);
}
.drop-zone-lv2 {
  width: 66%;
}
.drop-zone-lv2 .item-zone {
  display: flex;
  height: auto;
}
.drop-zone-lv2 .drag-el {
  width: 50%;
  padding-left: 10px;
  height: auto;
}
.drop-zone-lv2 .drag-el .bg-el {
  background: rgba(0, 0, 0, 0.04);
  padding: 5px;
  margin-bottom: 10px;
  height: auto;
}
.txt-area {
  width: 100% !important;
  overflow-y: hidden;
  resize: none;
  min-height: 20px;
  font-size: 14px !important;
}
@media only screen and (max-height: 1129px) {
  .tab-three-content {
    height: 620px;
  }
}
@media only screen and (max-height: 770px) {
  .tab-three-content {
    height: 500px;
  }
}
</style>
