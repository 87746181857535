<template>
  <v-form autocomplete="off">
    <v-container>
      <Label label="ユーザー検索"></Label>
      <SearchFormWrapper>
        <InputText
          class="search width-middle"
          placeholder="氏名"
          name="user_name"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <SelectWithFilter
          class="search width-middle"
          placeholder="所属"
          name="company_id"
          :items="companyList"
          item_text="name"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <InputText
          class="search width-middle"
          placeholder="メールアドレス"
          name="email"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <TabSelect
          class="search"
          name="role"
          :items="fieldUserRole"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <v-btn class="search" color="primary" @click="onSearch"> 検索 </v-btn>
      </SearchFormWrapper></v-container
    >
    <v-toolbar flat>
      <div class="center-title">検索結果 {{ searchParams.totalItems }}件</div>
    </v-toolbar>
    <v-data-table
      hide-default-footer
      disable-sort
      fixed-header
      :headers="TABLE_LABELS"
      :items="users"
      :items-per-page="searchParams.pageCount"
      :height="200"
      noDataText="アカウントが存在しません。"
    >
      <template v-slot:[`item.button`]="{ item }">
        <v-btn class="add-icon" icon @click="addUser(item)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ handleRole(item.role) }}
      </template>
    </v-data-table>
    <Pagination
      v-if="searchParams.pageCount <= searchParams.totalItems"
      :current="searchParams.currentPage"
      :total="searchParams.totalPage"
      @pageUpdate="pageUpdate"
    />
  </v-form>
</template>
<script>
import { Store } from "@/store/Store.js";
import {
  FORM_SITE_TABLE_LABELS,
  FIELD_USER_ROLE_ARRAY,
  FIELD_USER_ROLE,
} from "@/constants/FIELD_USER";

// Components
import Popup from "@/components/common/Popup";
import Pagination from "@/components/forms/elements/Pagination";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";

const TABLE_LABELS = FORM_SITE_TABLE_LABELS;

export default {
  components: {
    Popup,
    Pagination,
    FormDialogLayout,
    TabSelect,
    InputText,
    Label,
    SelectWithFilter,
    SearchFormWrapper,
  },
  data() {
    return {
      TABLE_LABELS,
      users: [],
      companyList: [],
      isSearched: false,
      pagination: {
        page: 1,
        total: 1,
        perPage: 100,
        visible: 7,
      },
      searchInputs: {
        user_name: null,
        company_id: null,
        role: [],
        email: null,
        sort_value: "user_name_kana",
        sort_by: 1,
      },
      searchParams: {
        pageCount: 25,
        currentPage: 1,
        totalPage: 1,
        totalItems: null,
      },
    };
  },
  props: {
    formUpdate: Function,
    mainHeight: Number,
    add: Array,
  },
  mounted() {
    this.$watch(
      () => Store.state.Company.data,
      (data) => {
        this.companyList = [...data];
        this.companyList.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.add,
      () => {
        this.getItems();
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    apiParams() {
      let ids = null;
      if (this.add.length > 0) ids = this.add.map((items) => items.id);
      return {
        field_id: this.currentSite.field_id,
        company_id: this.searchParams.company_id,
        user_name: this.searchParams.user_name,
        role: this.searchParams.role,
        email: this.searchParams.email,
        ids: ids,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        sort_value: this.searchParams.sort_value,
        sort_by: this.searchParams.sort_by,
      };
    },
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    fieldUserRole() {
      return Object.keys(FIELD_USER_ROLE).map((key) => {
        return FIELD_USER_ROLE[key];
      });
    },
  },
  methods: {
    async getItems(params) {
      if (!this.isSearched) {
        return;
      }
      this.$emit("loading", true);
      let searchParams = params ? params : this.searchParams;
      this.searchParams = { ...searchParams, ...this.searchInputs };
      const response = await Store.dispatch(
        `Users/getListUserForAsignToFields`,
        this.apiParams
      );
      if (!response.hasError) {
        const { entries, pagination } = response.data.contents;
        searchParams = { ...this.searchParams };
        searchParams.totalPage = pagination.total;
        searchParams.currentPage = pagination.current;
        searchParams.totalItems = pagination.total_item;
        this.searchParams = searchParams;
        this.users = entries;
      }
      this.$emit("loading", false);
    },
    handleRole(role) {
      return role == FIELD_USER_ROLE_ARRAY[1].value
        ? FIELD_USER_ROLE_ARRAY[1].name
        : "";
    },
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    onSearch() {
      if (!this.isSearched) {
        this.isSearched = true;
      }
      this.getItems();
    },
    addUser(item) {
      item.isNew = true;
      this.$emit("formUpdate", 1, item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.center-title {
  margin-left: 45%;
  font-weight: bold;
  font-size: 20px;
}
.add-icon {
  background-color: $accent_color;
  .v-icon {
    color: $primary_color !important;
  }
}
</style>
