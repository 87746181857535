import Vue from "vue";
import Vuex from "vuex";
import { sites } from "../../../api/modules/sites";

Vue.use(Vuex);

/**
 * api
 */

const ENTITY = sites;
export const Sites = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    sitesDetail: {},
    listCompanyByField: [],
    companyByFields: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_SITE_DETAIL(state, payload) {
      state.sitesDetail = payload;
    },
    SET_LIST_COMPANY_BY_FIELD(state, payload) {
      state.listCompanyByField = [...payload];
    },
    SET_COMPANY_BY_FIELD(state, payload) {
      state.companyByFields = [...payload];
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getSitesDetail({ commit }, payload) {
      const response = await ENTITY.getSitesDetail(payload);
      const entries = response.data.contents.entries;
      commit("SET_SITE_DETAIL", entries);
      return response;
    },
    async add(_context, payload) {
      return await ENTITY.add(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async getCompanyByField({ commit }, payload) {
      const response = await ENTITY.getCompanyByField(payload);
      const entries = response.data.contents.entries;
      commit("SET_LIST_COMPANY_BY_FIELD", entries);
      return response;
    },
    async getListCompanyByField({ commit }, payload) {
      const response = await ENTITY.getListCompanyByField(payload);
      const entries = response.data.contents.entries;
      commit("SET_COMPANY_BY_FIELD", entries);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getSitesDetail: (state) => {
      return state.sitesDetail;
    },
    getListCompanyByField: (state) => {
      return state.listCompanyByField;
    },
    getCompanyByFields: (state) => {
      return state.companyByFields;
    },
  },
};
