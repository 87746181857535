<template>
  <v-form autocomplete="off">
    <v-container>
      <Label label=""></Label>
      <SearchFormWrapper>
        <InputText
          class="search width-middle"
          placeholder="氏名"
          name="user_name"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <SelectWithFilter
          class="search width-middle"
          placeholder="所属"
          name="company_id"
          :items="companyList"
          item_text="name"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <InputText
          class="search width-middle"
          placeholder="メールアドレス"
          name="email"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <TabSelect
          class="search"
          name="role"
          :items="fieldUserRole"
          :editable="true"
          :values="searchInputs"
          @onInput="onChangeSearchInputs"
        />
        <v-btn class="search" color="primary" @click="onSearch"> 検索 </v-btn>
      </SearchFormWrapper></v-container
    >
    <v-toolbar flat>
      <div class="center-title">現場 {{ searchParams.totalItems }}件</div>
    </v-toolbar>
    <v-data-table
      hide-default-footer
      fixed-header
      disable-sort
      :headers="TABLE_LABELS"
      :items="fieldUsers"
      :items-per-page="searchParams.pageCount"
      :height="mainHeight - 230"
      noDataText="アカウントが存在しません"
    >
      <template v-slot:[`item.new`]="{ item }">
        <v-icon v-if="item.isNew" style="color: red !important"
          >mdi-new-box</v-icon
        >
      </template>
      <template v-slot:[`item.button`]="{ item }">
        <v-btn class="reject-icon" icon @click="reject(item)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ handleRole(item.role) }}
      </template>
    </v-data-table>
    <Pagination
      v-if="searchParams.pageCount <= searchParams.totalItems"
      :current="searchParams.currentPage"
      :total="searchParams.totalPage"
      @pageUpdate="pageUpdate"
    />
  </v-form>
</template>
<script>
import { Store } from "@/store/Store.js";
import {
  FORM_SITE_TABLE_LABELS,
  FIELD_USER_ROLE_ARRAY,
  FIELD_USER_ROLE,
} from "@/constants/FIELD_USER";
import { COMPANY_TYPE } from "@/constants/COMMON";

// Components
import Popup from "@/components/common/Popup";
import Pagination from "@/components/forms/elements/Pagination";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";

const TABLE_LABELS = FORM_SITE_TABLE_LABELS;

export default {
  components: {
    Popup,
    Pagination,
    TabSelect,
    InputText,
    Label,
    SelectWithFilter,
    SearchFormWrapper,
  },
  data() {
    return {
      TABLE_LABELS,
      fieldUsers: [],
      companyList: [],
      searchInputs: {
        user_name: null,
        company_id: null,
        role: [],
        email: null,
        sort_value: "user_name_kana",
        sort_by: 1,
      },
      searchParams: {
        pageCount: 25,
        currentPage: 1,
        totalPage: 1,
        totalItems: null,
      },
    };
  },
  props: {
    formUpdate: Function,
    mainHeight: Number,
    add: Array,
  },
  mounted() {
    this.getItems();
    this.$watch(
      () => Store.state.Company.data,
      (data) => {
        this.companyList = [...data];
        this.companyList.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.add,
      (data) => {
        if (data.length > 0) {
          this.fieldUsers = this.removeArray({
            from: this.fieldUsers,
            remove: data,
          });
          this.fieldUsers.unshift(...data);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    userLogin() {
      return Store.state.Login.user;
    },
    currentSite() {
      return Store.state.GlobalHeader.selectedSite;
    },
    apiParams() {
      return {
        field_id: this.currentSite.field_id,
        company_id: this.searchParams.company_id,
        user_name: this.searchParams.user_name,
        role: this.searchParams.role,
        email: this.searchParams.email,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        sort_value: this.searchParams.sort_value,
        sort_by: this.searchParams.sort_by,
      };
    },
    fieldUserRole() {
      return Object.keys(FIELD_USER_ROLE).map((key) => {
        return FIELD_USER_ROLE[key];
      });
    },
  },
  methods: {
    removeArray(arrs) {
      return [...arrs.from]?.filter((from) => {
        return ![...arrs.remove]?.some((remove) => {
          return remove.id === from.id;
        });
      });
    },
    async getItems(params) {
      this.$emit("loading", true);
      let company_type = this.userLogin.company_type;
      let company_id = this.searchInputs.company_id;
      if (!company_id) {
        company_id =
          company_type == COMPANY_TYPE.OWNER ||
          company_type == COMPANY_TYPE.ADMIN
            ? null
            : this.userLogin.company_id;
      }
      let searchParams = params ? params : this.searchParams;
      this.searchParams = { ...searchParams, ...this.searchInputs };
      this.searchParams.company_id = company_id;
      const response = await Store.dispatch(
        `FieldUser/getSort`,
        this.apiParams
      );
      if (!response.hasError) {
        const { entries, pagination } = response.data.contents;
        searchParams = { ...this.searchParams };
        searchParams.totalPage = pagination.total;
        searchParams.currentPage = pagination.current;
        searchParams.totalItems = pagination.total_item;
        this.searchParams = searchParams;
        this.fieldUsers = entries;
      }
      this.$emit("loading", false);
    },
    handleRole(role) {
      return role == FIELD_USER_ROLE_ARRAY[1].value
        ? FIELD_USER_ROLE_ARRAY[1].name
        : "";
    },
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    onSearch() {
      this.getItems();
    },
    reject(item) {
      let index = this.fieldUsers.findIndex((el) => {
        return el.id === item.id;
      });
      if (index >= 0) {
        this.$delete(this.fieldUsers, index);
      }
      this.$emit("formUpdate", 0, item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.center-title {
  margin-left: 45%;
  font-weight: bold;
  font-size: 20px;
}
.reject-icon {
  background-color: $warning_color;
  .v-icon {
    color: $primary_color !important;
  }
}
</style>
