<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="dialog-title">{{ title }}</div>
    </v-card-title>
    <v-card-text v-if="!isExport">
      <div v-if="!isReset" class="confirm-text">
        {{ text }}
      </div>
      <div v-if="isReset" class="reset-text">
        {{ text }}様のログインパスワードを「<span class="default-password"
          >12345678</span
        >」にリセットします。
      </div>
    </v-card-text>
    <v-card-text v-if="hasDetails">
      <div v-if="!isExport" class="details">
        <span class="item" v-for="item in items" :key="item.id">
          {{ getDetailText(item) }}</span
        >
      </div>
      <v-radio-group v-if="isExport" v-model="radioGroup">
        <v-radio label="PDF" color="success" :value="2"></v-radio>
        <v-radio label="Excel" color="success" :value="1"></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions v-if="isTwoButton">
      <v-spacer></v-spacer>
      <v-btn
        v-if="isCancel"
        class="btn dialog-close-btn"
        @click="$emit('close')"
      >
        {{ DIALOG_BUTTON_NAMES.CANCEL }}
      </v-btn>
      <v-btn
        v-if="isDelete"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes')"
      >
        {{ DIALOG_BUTTON_NAMES.DELETE }}
      </v-btn>
      <v-btn
        v-if="isUnlock"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes')"
      >
        {{ DIALOG_BUTTON_NAMES.UNLOCK }}
      </v-btn>
      <v-btn
        v-if="isAccept"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes')"
      >
        {{ DIALOG_BUTTON_NAMES.ACCEPT }}
      </v-btn>
      <v-btn
        v-if="isConfirm"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes')"
      >
        {{ DIALOG_BUTTON_NAMES.CONFIRM }}
      </v-btn>
      <v-btn
        v-if="isInvite"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes')"
      >
        {{ DIALOG_BUTTON_NAMES.INVITE }}
      </v-btn>
      <v-btn
        v-if="isExport"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.EXPORT }}
      </v-btn>
      <v-btn
        v-if="isEndUse"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.END }}
      </v-btn>
      <v-btn
        v-if="isUndo"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.UNDO }}
      </v-btn>
      <v-btn
        v-if="isApproval"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.APPROVAL }}
      </v-btn>
      <v-btn
        v-if="isGuidance"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.GUIDANCE }}
      </v-btn>
      <v-btn
        v-if="isClose"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.CLOSE }}
      </v-btn>
      <v-btn
        v-if="isReset"
        class="btn dialog-submit-btn"
        @click.once="$emit('yes', radioGroup)"
      >
        {{ DIALOG_BUTTON_NAMES.RESET }}
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="!isTwoButton">
      <v-spacer></v-spacer>
      <v-btn class="btn dialog-close-btn" @click="$emit('close')">
        {{ DIALOG_BUTTON_NAMES.OK }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DIALOG_BUTTON_NAMES, COLORS } from "@/constants/COMMON";

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
    },
    hasDetails: {
      type: Boolean,
      default: true,
    },
    isUserDetails: {
      type: Boolean,
      default: false,
    },
    isSiteDetails: {
      type: Boolean,
      default: false,
    },
    isMachineDetails: {
      type: Boolean,
      default: false,
    },
    isFieldMachineDetails: {
      type: Boolean,
      default: false,
    },
    isTwoButton: {
      type: Boolean,
      default: true,
    },
    isCancel: {
      type: Boolean,
      default: true,
    },
    isEndUse: {
      type: Boolean,
      default: false,
    },
    isUnlock: {
      type: Boolean,
      default: false,
    },
    isInvite: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
    isAccept: {
      type: Boolean,
      default: false,
    },
    isApproval: {
      type: Boolean,
      default: false,
    },
    isGuidance: {
      type: Boolean,
      default: false,
    },
    isClose: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isExport: {
      type: Boolean,
      default: false,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    isUndo: {
      type: Boolean,
      default: false,
    },
    isInformation: {
      type: Boolean,
      default: false,
    },
    isInspection: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({ DIALOG_BUTTON_NAMES, radioGroup: 2 }),
  mounted() {
    this.setTextAlign();
    this.setButtonColor();
    this.setTitleColor();
  },
  computed: {},
  created() {},
  methods: {
    setTitleColor() {
      let el = document.querySelector(`.dialog-title`);
      if (this.isExport) {
        el.style.cssText = `color: ${COLORS.EXPORT_COLOR};`;
      } else if (this.isApproval || this.isGuidance) {
        el.style.cssText = `color: ${COLORS.ACCENT_COLOR};`;
      } else {
        el.style.cssText = `color: ${COLORS.WARNING_COLOR} !important;`;
      }
    },
    setTextAlign() {
      if (!this.text) return;
      let el = this.isReset
        ? document.querySelector(`.reset-text`)
        : document.querySelector(`.confirm-text`);
      if (this.isCenter) {
        el.style.cssText = `text-align: center;`;
      } else {
        el.style.cssText = `text-align: left; padding-left: 30px;`;
      }
    },
    setButtonColor() {
      let btnClose = document.querySelector(`.dialog-close-btn`);
      let btnSubmit = document.querySelector(`.dialog-submit-btn`);
      if (this.isExport) {
        btnClose.style.cssText = `color: ${COLORS.ACCENT_COLOR}; border: 1px solid ${COLORS.ACCENT_COLOR};`;
        if (this.isTwoButton)
          btnSubmit.style.cssText = `background: ${COLORS.ACCENT_COLOR};`;
      } else if (this.isApproval || this.isGuidance) {
        btnClose.style.cssText = `background: ${COLORS.CANCEL_COLOR}`;
        if (this.isTwoButton)
          btnSubmit.style.cssText = `background: ${COLORS.ACCENT_COLOR};`;
      } else {
        btnClose.style.cssText = `color: ${COLORS.WARNING_COLOR}; border: 1px solid ${COLORS.WARNING_COLOR};`;
        if (this.isTwoButton)
          btnSubmit.style.cssText = `background: ${COLORS.WARNING_COLOR};`;
      }
    },
    getDetailText(item) {
      if (this.isUserDetails) {
        return item.name_sei + " " + item.name_mei;
      } else if (this.isSiteDetails || this.isMachineDetails) {
        return item.name;
      } else if (this.isFieldMachineDetails) {
        return item.machine_name;
      } else if (this.isInspection) {
        return item.quantity + "台の新規登録が完了しました。";
      } else {
        return item;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.dialog {
  height: auto !important;
  padding-bottom: 10px;
  .dialog-title {
    font-size: 20px !important;
  }
  .confirm-text {
    padding: 10px 0;
    width: 100%;
    font-size: 16px;
    white-space: pre-line;
  }
  .reset-text {
    width: 100%;
    font-size: 16px;
    white-space: pre-line;
    .default-password {
      font-weight: 700;
    }
  }
  .details {
    font-size: 14px;
  }
  .btn {
    margin-right: 10px;
    border-radius: 5px;
    width: 20%;
    font-size: 14px !important;
  }
  .dialog-close-btn {
    background: $primary_color;
  }
  .dialog-submit-btn {
    color: $primary_color;
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
