<template>
  <div>
    <SingleLayout>
      <v-card class="mx-auto my-12 mt-0 mb-0" max-width="374" outlined>
        <div class="login-header">
          <img src="@/assets/rakuraku.png" />
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-list-item>
              <v-list-item-content>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="メール"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="formValue.login_id"
                    :error-messages="errors"
                    :success="valid"
                    autofocus
                    dense
                    height="48px"
                    placeholder="ユーザーID"
                  ></v-text-field>
                </ValidationProvider>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="パスワード"
                  rules="required|password"
                >
                  <v-text-field
                    v-model="formValue.password"
                    :error-messages="errors"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow ? 'text' : 'password'"
                    dense
                    height="48px"
                    placeholder="パスワード"
                    @click:append="passwordShow = !passwordShow"
                  ></v-text-field>
                </ValidationProvider>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                  label="ログイン情報を保存する"
                  v-model="formValue.save_info"
                  dense
                ></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  height="48px"
                  color="primary"
                  :disabled="invalid"
                  @click="checkAndLogin"
                  >ログイン</v-btn
                >
              </v-list-item-content>
            </v-list-item>
            <div class="links pt-0">
              <v-btn
                style="color: #083874"
                text
                @click="isShowResetPasswordDialog = true"
              >
                パスワードを忘れた場合はこちら</v-btn
              >
            </div>
          </v-form>
        </ValidationObserver>
      </v-card>
    </SingleLayout>
    <!-- メールリセット -->
    <Popup width="372px" :dialog="isShowResetPasswordDialog">
      <ResetMail @close="isShowResetPasswordDialog = false" />
    </Popup>
    <!-- Show popup fill Q-A for old users -->
    <Popup width="350px" :dialog="isShowFillQAForOldUsers">
      <FillQA
        @close="isShowFillQAForOldUsers = false"
        @login-without-fillQA="Login"
        @login-with-fillQA="LoginWithFilled"
      />
    </Popup>
    <!-- Show popup set new password for users -->
    <Popup width="350px" :dialog="isShowSetNewPassword">
      <SetNewPass
        @close="isShowSetNewPassword = false"
        @set_new_pass="setNewPass"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "../../components/layout/SingleLayout";
import ResetMail from "./resetMail/index.vue";
import FillQA from "./fillQA/index.vue";
import SetNewPass from "./setNewPassword/index.vue";
import Popup from "@/components/common/Popup.vue";
import { ROLE_LOGIN } from "@/constants/LOGIN.js";
import {
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_FIELD,
} from "@/constants/GLOBALHEADER";

const STORE = "Users";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "ログイン" };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    ResetMail,
    Popup,
    FillQA,
    SetNewPass,
  },
  data: () => {
    return {
      formValue: {
        login_id: "",
        password: "",
        role: null, // 管理者
        save_info: false,
      },
      passwordShow: false,
      isShowResetPasswordDialog: false,
      isShowFillQAForOldUsers: false,
      isShowSetNewPassword: false,
      resLogin: {},
    };
  },
  methods: {
    async setNewPass(value) {
      let passwordNew = value.password_new;
      let user_id = this.resLogin.contents.entries.user.id;
      //check password in form setNewPass with password in Login page
      if (value.password == this.formValue.password) {
        //set flg to user table
        const resGetUser = await Store.dispatch(
          `${STORE}/getUserDetail`,
          user_id
        );
        if (resGetUser.hasError) {
          Store.dispatch("Error/show", {
            status: 200,
            message: "入力した値が違います。正しい値を入力してください。",
          });
        } else {
          let passNew = { ...resGetUser.data.contents.entries };
          //set flg and password to user
          passNew["reset_pass_flg"] = 0;
          passNew["password"] = passwordNew;
          const resReset = await Store.dispatch(`${STORE}/update`, passNew);
          if (resReset.hasError) {
            Store.dispatch("Error/show", {
              status: 200,
              message: "入力した値が違います。正しい値を入力してください。",
            });
          } else {
            Store.dispatch("Toast/show", {
              status: 200,
              message: "パスワードの再設定が成功しました。",
            });
            this.formValue.password = passwordNew;
            this.Login();
          }
        }
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: "現在のパスワードは正しくありません",
        });
      }
    },
    async LoginWithFilled(data) {
      const res = await Store.dispatch("Login/login", this.formValue);
      if (typeof res !== "undefined" && "result" in res && res.result) {
        let arrItem = { ...res.contents.entries.user };
        arrItem["question"] = data.val.question;
        arrItem["answer"] = data.val.answer;
        const result = await Store.dispatch(`${STORE}/update`, arrItem);
        if (result.hasError) {
          const { data } = result.response;
          Store.dispatch("Error/show", {
            status: data.status_code,
            message: "質問、回答の追加中にエラーが発生しました。",
          });
        } else {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "質問、回答が正常に追加されました。",
          });
          this.Login();
        }
      } else {
        Store.dispatch("Error/show", {
          status: 500,
          message: "質問、回答の追加中にエラーが発生しました。",
        });
      }
    },
    async checkAndLogin() {
      this.resLogin = await Store.dispatch("Login/login", this.formValue);

      if (typeof this.resLogin === "undefined") {
        return;
      }

      const user = this.resLogin.contents.entries.user;
      if (user.role === ROLE_LOGIN.INSPECTOR.id) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "点検者アカウントはアクセスできません。",
        });
        return;
      }
      //check to show popup fillQA user
      const isOldUser = user.is_old_user;
      if (isOldUser == true) {
        this.isShowFillQAForOldUsers = true;
      } else {
        //check to show popop setNewPass
        const isResetPass = user.reset_pass_flg;
        isResetPass == 1 ? (this.isShowSetNewPassword = true) : this.Login();
      }
    },
    /**
     * ログイン
     */
    async Login() {
      // const res = await Store.dispatch("Login/login", this.formValue);
      const res = this.resLogin;
      if ("result" in res && res.result) {
        if (res.contents.entries.user.first_fields_id) {
          const field_id = res.contents.entries.user.first_fields_id;
          await Store.dispatch("GlobalHeader/searchSites", {
            keyword: "",
          });
          Store.dispatch("GlobalHeader/setSite", {
            field_id: field_id,
          });
          this.$router.push(HEADER_MENU_ITEMS_FIELD.INSPECTOR_FIELD.path);
        } else {
          this.$router.push(HEADER_MENU_ITEMS_INHOUSE.MACHINES.path);
        }

        if (this.formValue.save_info) {
          localStorage.login_id = this.formValue.login_id;
          localStorage.password = this.formValue.password;
          localStorage.save_info = this.formValue.save_info;
        } else {
          localStorage.removeItem("login_id");
          localStorage.removeItem("password");
          localStorage.removeItem("save_info");
        }
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.message,
        });
      }
    },
  },
  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    if (localStorage.getItem("save_info")) {
      this.formValue.save_info = localStorage.getItem("save_info");
      this.formValue.login_id = localStorage.getItem("login_id");
      this.formValue.password = localStorage.getItem("password");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  padding-top: 32px;
  text-align: center;
  color: #00c3ac;

  img {
    width: 240px;
  }
}

.links {
  padding: 8px;
  text-align: center;
}

.v-label,
.v-btn,
.v-input--checkbox::v-deep label,
.v-text-field::v-deep input {
  font-size: 16px !important;
}
</style>
