<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :addButtonTitle="ADD_BUTTON_TITLE"
              :disableBtnAdd="disableBtnAdd"
              :isSearch="false"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
            </TableHeader>
            <SearchFormWrapper>
              <InputText
                class="search width-large"
                placeholder="会社名"
                name="word"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <TabSelect
                class="search"
                name="company_type"
                :items="COMPANY_CONTRACT"
                :editable="true"
                :values="searchInputs"
                @onInput="onChangeSearchInputs"
              />
              <v-btn class="search" color="primary" @click="onSearch">
                検索
              </v-btn>
            </SearchFormWrapper>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
              @dblclick:row="($event, { item }) => openItemForm(item)"
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ handleCompanyName(item) }}
              </template>
              <template v-slot:[`item.company_type`]="{ item }">
                {{ handleCompanyType(item.company_type) }}
              </template>
              <template v-slot:[`item.address`]="{ item }">
                {{ handleAddress(item) }}
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <CompanyForm
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
        ref="CompanyForm"
      />
    </Popup>

    <Popup width="500px" :dialog="popups.isShowRemoveDialog">
      <ConfirmDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        :isDelete="true"
        :isMachineDetails="true"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
      />
    </Popup>
    <Loader :isLoading="isLoading"></Loader>
  </div>
</template>
<script>
/**
 * (Common)
 * Common and functions
 */
import { Store } from "@/store/Store.js";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";
import {
  COMPANY_TABLE_LABELS,
  COMPANY_SORT_ITEMS,
  COMPANY_CONTRACT,
  COMPANY_TYPE_ARR,
  COPORATE_PREFIX_ARR,
} from "@/constants/COMPANY";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

/**
 * Components
 */
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/forms/elements/TableHeader";
import Pagination from "@/components/forms/elements/Pagination";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/forms/elements/TableSortWrapper";
import TableSort from "@/components/forms/elements/TableSort";
import Popup from "@/components/common/Popup";
import Loader from "@/components/forms/elements/Loader";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import InputText from "@/components/forms/elements/InputText";
import Label from "@/components/forms/elements/Label";
import TabSelect from "@/components/forms/elements/TabSelect";
import CompanyForm from "@/components/forms/company/index";

// page_title
const PAGE_TITLE = "会社";

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "Company";

// Table header label
const TABLE_LABELS = COMPANY_TABLE_LABELS;

// Sort element
const SORT_ITEMS = COMPANY_SORT_ITEMS;

// Button add title
const ADD_BUTTON_TITLE = "新規作成";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    Loader,
    ConfirmDialog,
    InputText,
    Label,
    TabSelect,
    CompanyForm,
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      ADD_BUTTON_TITLE,
      COMPANY_TYPE,
      items: [],
      selectedItems: [],
      isNewItem: false,
      isLoading: false,
      // searchParams
      searchParams: {
        word: "",
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
        company_type: [],
      },
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
      },
      searchInputs: {
        word: "",
        company_type: [],
      },
    };
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.COMPANY.id,
    });
    Store.dispatch("GlobalHeader/setSite", {
      field_id: null,
    });
    /**
     * (Common)
     * get data table
     */
    this.getItems();

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    COMPANY_CONTRACT() {
      return Object.keys(COMPANY_CONTRACT).map((key) => {
        return COMPANY_CONTRACT[key];
      });
    },

    apiParams() {
      return {
        name: this.searchParams.word,
        company_type: this.searchParams.company_type,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        sort_value: this.searchParams.sort,
      };
    },

    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.state.Login.user?.company_type;
    },

    /**
     * set condition disabled button add
     */
    disableBtnAdd() {
      return (
        this.companyType === this.COMPANY_TYPE.PATNER ||
        this.companyType === this.COMPANY_TYPE.RENTAL
      );
    },
  },

  methods: {
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = { ...this.searchParams, ...this.searchInputs };
      this.searchParams["currentPage"] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common)
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * Open new/detail/edit form
     */
    openNewItemForm() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getCompanyDetail`, item.id);
      if (!result.hasError) {
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.getItems();
    },

    /**
     *  (Common)
     * open Remove Dialog
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    /**
     *  (Common)
     * API get data
     */
    async getItems() {
      this.isLoading = true;
      await Store.dispatch(`${STORE}/get`, this.apiParams);
      this.isLoading = false;
    },

    // Remove
    async removeItems() {
      const company_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { company_ids });

      if (!result.hasError) {
        // get items
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
      // close dialog
      this.closeRemoveDialog();
    },

    handleCompanyName(item) {
      const { corporate_type, name } = item;
      const prefix = COPORATE_PREFIX_ARR.find(
        (item) => item.id == corporate_type
      )?.name;
      if (prefix) {
        return prefix
          .replace("（会社名）", name)
          .replace("（登記名or個人名）", name);
      } else {
        return name;
      }
    },

    /**
     * convert Company Type id -> name
     */
    handleCompanyType(company_type) {
      const company = COMPANY_TYPE_ARR.find(
        (item) => item.value == company_type
      );
      return company?.name;
    },
    /**
     * get adrress
     * prefecture + city + address
     */
    handleAddress(item) {
      return `${item.prefecture || ""} ${item.city || ""} ${
        item.address || ""
      }`;
    },
  },
};
</script>
