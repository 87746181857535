<template>
  <div>
    <div class="sticky">
      <SearchFormWrapper>
        <!-- 検索ワード -->
        <InputText
          class="search flex-grow-1"
          name="keyword"
          :editable="true"
          :clearable="true"
          :values="formValues"
          placeholder="検索"
          validation_label="検索ワード"
          validation_rules="max:25"
          innerIcon="mdi-magnify"
          @onInput="onInput"
        />
        <!-- 検索ボタン -->
        <v-btn @click="$emit('search')" color="primary">検索</v-btn>
      </SearchFormWrapper>
    </div>
    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import InputText from "@/components/forms/elements/InputText";
import SearchFormWrapper from "@/components/forms/elements/SearchFormWrapper";

export default {
  components: {
    InputText,
    SearchFormWrapper,
  },
  data() {
    return {
      values: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    };
  },
  props: {
    formValues: Object,
    search: Function,
    top: {
      type: Number,
      default: 65,
    },
  },
  async mounted() {
    this.setTop();
    // formに表示する値を更新
    this.$watch(
      () => this.formValues,
      (newValue) => {
        if (newValue) this.values = newValue;
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    setTop() {
      let el = document.querySelector(`.sticky`);
      if (el) el.style.cssText = `top: ${this.top}px;`;
    },
    // idの更新
    onClick(id) {
      this.values.selected = id;
      this.$emit("formUpdate", this.values);
    },
    onInput({ name, value }) {
      this.values[name] = value;
      this.$emit("formUpdate", this.values);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.sticky {
  position: sticky;
  z-index: 1;
  background-color: $background_color;
}
::v-deep .v-text-field__details {
  display: none !important;
}
</style>
