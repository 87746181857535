<template>
  <v-form autocomplete="off">
    <div class="d-flex justify-space-between">
      <div
        class="flex-column flex-fill mr-15 align-self-start"
        style="width: 50%"
      >
        <v-list-item>
          <v-list-item-content>
            <Label label=""></Label>
            <Label label="現場名" :editable="editable" required>
              <InputText
                name="name"
                :values="formValues"
                :editable="editable"
                :autofocus="autofocus"
                validation_label="現場名"
                validation_rules="required|max:255"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex">
              <Label
                class="flex-grow-1 mr-5"
                label="郵便番号"
                :editable="editable"
                required
              >
                <InputText
                  name="postal_code"
                  :values="formValues"
                  :editable="editable"
                  placeholder="001-0010"
                  validation_label="郵便番号"
                  validation_rules="required|max:8|postcode"
                  @onInput="onChangePostalcode"
                />
              </Label>
              <Label
                class="flex-grow-1 ml-5"
                label="都道府県"
                :editable="editable"
                required
              >
                <SelectWithFilter
                  pinId="selectSiteFormPage11"
                  name="prefecture_id"
                  :values="formValues"
                  :items="PREFECTURES"
                  item_text="prefecture"
                  :editable="editable"
                  validation_label="都道府県"
                  validation_rules="required|max:127"
                  @onInput="onInput"
                />
              </Label>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="市区町村" :editable="editable" required>
              <InputText
                name="city"
                :values="formValues"
                :editable="editable"
                placeholder="千代田区"
                validation_label="市区町村"
                validation_rules="required|max:60"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label label="番地・建物名" :editable="editable" required>
              <InputText
                name="address"
                :values="formValues"
                :editable="editable"
                placeholder="番地・建物名"
                validation_label="番地・建物名"
                validation_rules="required|max:120"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div
        class="flex-column flex-fill ml-15 align-self-start"
        style="width: 50%"
      >
        <v-list-item>
          <v-list-item-content>
            <Label label="機械管理責任者" :editable="editable"></Label>
            <div class="d-flex">
              <Label class="flex-grow-1 mr-5" label="会社" :editable="editable">
                <SelectWithFilter
                  pinId="selectFilterSiteFormPage11"
                  name="company_id"
                  :values="formValues"
                  :items="COMPANY_ARRAY"
                  :editable="editable"
                  validation_label="会社"
                  @onInput="onInput"
                />
              </Label>
              <Label class="flex-grow-1 ml-5" label="氏名" :editable="editable">
                <SelectWithFilter
                  :filter-custom="true"
                  pinId="selectFilterSiteFormPage12"
                  name="machine_manager_id"
                  :values="formValues"
                  :items="MACHINE_MANAGER_ARRAY"
                  :editable="editable"
                  validation_label="氏名"
                  @onInput="onInput"
                />
              </Label>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <Label
              label="月例点検提出締切・メール送信設定"
              :editable="editable"
            >
              <SwitchInput
                name="report_checking_flg"
                label="OFF／ON"
                :values="formValues"
                :editable="editable"
                validation_label="メール送信設定"
                @onInput="onChangeReportFlg"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex">
              <Label
                class="flex-grow-1 mr-5"
                label="毎月"
                :editable="editable"
                :required="toggle_on"
              >
                <SelectWithFilter
                  pinId="selectSiteFormPage12"
                  name="report_checking_day"
                  :values="formValues"
                  :items="DAYS"
                  :editable="editable && toggle_on"
                  validation_label="毎月"
                  :validation_rules="require_on"
                  @onInput="onInput"
                />
              </Label>
              <Label class="flex-grow-1 ml-5" label=""></Label>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <div id="specialSelectFilter1"></div>
          <v-list-item-content>
            <Label label="問い合わせ先" :editable="editable"></Label>
            <div class="d-flex">
              <Label class="flex-grow-1 mr-5" label="会社" :editable="editable">
                <SelectWithFilter
                  pinIdSpecial="specialSelectFilter1"
                  pinId="specialSelectFilter1"
                  name="contact_company_id"
                  :values="formValues"
                  :items="COMPANY_ARRAY"
                  :editable="editable"
                  validation_label="会社"
                  @onInput="onInput"
                />
              </Label>
              <Label
                class="flex-grow-1 mx-5"
                label="担当者"
                :editable="editable"
              >
                <SelectWithFilter
                  :filter-custom="true"
                  pinIdSpecial="specialSelectFilter2"
                  pinId="specialSelectFilter2"
                  name="contact_user_id"
                  :values="formValues"
                  :items="CONTACT_USER"
                  :editable="editable"
                  validation_label="担当者"
                  @onInput="onInput"
                />
              </Label>
              <Label
                class="flex-grow-1 ml-5"
                label="連絡先"
                :editable="editable"
              >
                <InputText
                  name="contact_phone"
                  :values="formValues"
                  :editable="false"
                  validation_label="連絡先"
                  @onInput="onInput"
                />
              </Label>
            </div>
          </v-list-item-content>
        </v-list-item>
        <div id="specialSelectFilter2"></div>
      </div>
    </div>
  </v-form>
</template>
<script>
import { Store } from "@/store/Store.js";
import { COMPANY_TYPE_MAIN_CONTRACTOR } from "@/constants/COMPANY";
import { USER_ROLE } from "@/constants/USER.js";
import { DAYS } from "@/constants/SITES";
import { postalCodeSearch } from "@/utils/postalCodeSearch";

// Components
import Label from "@/components/forms//elements/Label";
import InputText from "@/components/forms/elements/InputText";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter";

export default {
  data: () => {
    return {
      formValues: {},
      PREFECTURES: Store.state.CmnMst.constants.entries,
      autofocus: false,
      COMPANY_ARRAY: [],
      CONTACT_USER: [{ id: null, name: "" }],
      MACHINE_MANAGER_ARRAY: [{ id: null, name: "" }],
      DAYS,
      USER_ROLE,
      toggle_on: false,
      require_on: "",
    };
  },
  components: {
    Label,
    InputText,
    SwitchInput,
    SelectWithFilter,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.getDataCompany();
    this.$watch(
      () => this.item,
      (newValue, oldValue) => {
        this.formValues = { ...newValue };
        if (newValue?.company_id != oldValue?.company_id && !this.editable) {
          this.getDataMachineManagerByCompanyId(newValue.company_id);
        }
        if (
          newValue?.contact_company_id != oldValue?.contact_company_id &&
          !this.editable
        ) {
          this.getListUsers(newValue.contact_company_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        if (newValue) {
          this.toggle_on =
            this.formValues.report_checking_flg == 1 ? true : false;
          this.require_on =
            this.formValues.report_checking_flg == 1 ? "required" : "";
        }
        if (newValue && !this.isNewItem) {
          const companyNameElement = document.getElementsByTagName("input");
          companyNameElement[6].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Company/getData"],
      (data) => {
        this.COMPANY_ARRAY = [...data];
        this.COMPANY_ARRAY.unshift({
          id: null,
          name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {},
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      if (name == "company_id") {
        formValues.machine_manager_id = null;
        this.getDataMachineManagerByCompanyId(value);
      } else if (name === "contact_company_id") {
        formValues["contact_user_id"] = null;
        formValues["contact_phone"] = null;
        this.CONTACT_USER = [{ id: null, name: "" }];
        if (value) this.getListUsers(value);
      } else if (name === "contact_user_id") {
        let user = this.CONTACT_USER.find((e) => value && e.id === value);
        formValues["contact_phone"] = user?.tel || null;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    onChangeReportFlg({ value }) {
      const formValues = { ...this.formValues };
      formValues.report_checking_flg = value;
      if (value == 1) {
        this.toggle_on = true;
        this.require_on = "required";
      } else {
        this.toggle_on = false;
        this.require_on = "";
        formValues.report_checking_day = "";
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getDataCompany() {
      let apiParams = { company_type: [COMPANY_TYPE_MAIN_CONTRACTOR] };
      await Store.dispatch(`Company/get`, apiParams);
    },
    async getDataMachineManagerByCompanyId(id) {
      if (id) {
        let apiParams = { company_id: id };
        const response = await Store.dispatch(`Users/get`, apiParams);
        this.MACHINE_MANAGER_ARRAY = [...response.data.contents.entries];
        this.MACHINE_MANAGER_ARRAY.unshift({ id: null, name: "" });
      } else {
        this.MACHINE_MANAGER_ARRAY = [{ id: null, name: "" }];
      }
    },
    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalcode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      if (value == "") {
        formValues.prefecture_id = null;
        formValues.city = "";
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if (result) {
          formValues.prefecture_id = result.prefecture.id;
          formValues.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    /**
     * Call API get data for 担当者
     */
    async getListUsers(company_id) {
      let apiParams = {
        company_id,
        role: [USER_ROLE[0].id],
      };
      const response = await Store.dispatch(`Users/get`, apiParams);
      this.CONTACT_USER = [...response.data.contents.entries];
      this.CONTACT_USER.unshift({ id: null, name: "" });
      // if detail then get data contact_phone
      if (!this.editable) this.getPhoneByUser();
    },

    /**
     * get contact_phone by contact_user_id
     */
    getPhoneByUser() {
      let formValues = { ...this.formValues };
      let userId = formValues?.contact_user_id;
      if (userId) {
        let user = this.CONTACT_USER.find((e) => e.id === userId);
        formValues["contact_phone"] = user?.tel;
      } else {
        formValues["contact_phone"] = null;
      }
      this.formValues = formValues;
    },
  },
};
</script>
<style lang="scss" scoped>
.rowInfoContact {
  margin-top: 7px;
}

#specialSelectFilter1 {
  ::v-deep .v-menu__content {
    margin-left: 4px;
    bottom: 82px !important;
    top: auto !important;
    left: auto !important;
    max-width: fit-content !important;
  }
}
#specialSelectFilter2 {
  ::v-deep .v-menu__content {
    margin-left: 20px;
    bottom: 99px !important;
    top: auto !important;
    left: auto !important;
    max-width: fit-content !important;
  }
}
</style>
