import Vue from "vue";
import Vuex from "vuex";
import { users } from "../../../api/modules/users";

Vue.use(Vuex);

/**
 * apiを指定
 */

const ENTITY = users;
export const Users = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    userDetail: {},
    userAsigns: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_USER_DETAIL(state, payload) {
      state.userDetail = payload;
    },
    SET_USER_ASIGN(state, payload) {
      state.userAsigns = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      let userNameFilter = payload.user_name;
      if (
        userNameFilter &&
        userNameFilter != "" &&
        !userNameFilter.match(/(?<=\S)\s(?=\S)/g)
      ) {
        userNameFilter = userNameFilter.trim();
        payload.user_name = null;
        payload.page_size = null;
        const responseCustom = await ENTITY.get(payload);
        if (!responseCustom.hasError) {
          let { entries, pagination } = responseCustom.data.contents;
          entries = entries.filter(
            (e) => e.full_name.replace(/ +/g, "").indexOf(userNameFilter) >= 0
          );
          if (payload.page_size) {
            if (
              (payload.page_size == 25 && entries.length >= 25) ||
              (payload.page_size == 50 && entries.length >= 50) ||
              (payload.page_size == 75 && entries.length >= 75) ||
              (payload.page_size == 75 && entries.length >= 100)
            ) {
              pagination.total = Math.floor(entries.length / payload.page_size);
            }
          }
          pagination.total_item = entries.length;
          commit("SET_DATA", entries);
          commit("SET_PAGINATION", pagination);
          responseCustom.data.contents.entries = entries;
          return responseCustom;
        } else {
          return responseCustom;
        }
      } else {
        const response = await ENTITY.get(payload);
        if (response.hasError) {
          return response;
        }
        const { entries, pagination } = response.data.contents;
        commit("SET_DATA", entries);
        commit("SET_PAGINATION", pagination);
        return response;
      }
    },
    async getListUserForAsignToFields({ commit }, payload) {
      const response = await ENTITY.getListUserForAsignToFields(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_USER_ASIGN", entries);
      return response;
    },
    async getUserDetail({ commit }, payload) {
      const response = await ENTITY.getUserDetail(payload);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;
      commit("SET_USER_DETAIL", entries);
      return response;
    },
    async post(_context, payload) {
      return await ENTITY.post(payload);
    },
    async update(_context, payload) {
      return await ENTITY.update(payload);
    },
    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
    async inviteUsers(_context, payload) {
      return await ENTITY.inviteUsers(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getUserDetail: (state) => {
      return state.userDetail;
    },
    getUserAsign: (state) => {
      return state.userAsigns;
    },
  },
};
