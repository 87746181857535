<template>
  <v-pagination
    v-model="page"
    :length="total"
    :total-visible="7"
    color="primary"
    circle
    @input="update"
    @next="update"
    @previous="update"
  ></v-pagination>
</template>
<script>
export default {
  data: () => {
    return {
      page: 1,
    };
  },
  props: {
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.current,
      (newValue) => {
        this.page = newValue;
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    update() {
      this.$emit("pageUpdate", this.page);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

::v-deep .v-pagination__item:not(.v-pagination__item--active) {
  border: none !important;
  border-radius: 0 !important;
  background-color: $background_color;
  box-shadow: none !important;
}
</style>
